export default function StoreContract() {

    return (

        <div className="max-w-5xl mx-auto px-2 py-12">
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "11.25pt",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>
                        شروط وأحكام بيع السلع والخدمات عبر الإنترنت
                    </span>
                </strong>
            </p>
            <ol style={{ listStyleType: "decimal" }}>
                <li>
                    <span style={{ lineHeight: "150%", fontSize: 19 }}>
                        يحتوي هذا المستند على معلومات مهمة جدًا بخصوص حقوقك والتزاماتك ،
                        بالإضافة إلى الشروط والقيود والاستثناءات التي قد تنطبق عليك. يرجى
                        قراءتها بعناية
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                    <ul style={{ listStyleType: "disc" }}>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                من خلال تقديم طلب للحصول على منتجات أو خدمات من هذا الموقع ("
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>ezone</span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                ") و / أو تطبيق الهاتف المحمول ("التطبيق")، فإنك تؤكد أنك تبلغ السن
                                القانوني لإبرام هذه الاتفاقية ، وأنك تقبل وتلتزم بموجب هذه الشروط
                                والأحكام. أنت تؤكد أنه إذا قمت بتقديم طلب نيابة عن منظمة أو شركة ،
                                فلديك السلطة القانونية لإلزام أي مؤسسة أو شركة بهذه الشروط والأحكام
                                ، ولا يجوز لك طلب أو الحصول على منتجات أو خدمات من هذا الموقع و / أو
                                التطبيق إذا كنت: (1) لا توافق على هذه الشروط والأحكام ؛ (2) لم تبلغ
                                سن الرشد في مقاطعتك أو إقليم إقامتك ؛ أو (3) ممنوع من الوصول إلى أو
                                استخدام هذا الموقع أو التطبيق أو أي من محتوياته أو منتجاته أو خدماته
                                بموجب القانون المعمول به
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                تنطبق هذه الشروط والأحكام (هذه "الشروط") على شراء وبيع المنتجات
                                والخدمات من خلال&nbsp;
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>www.ezone.ly</span>
                            <span style={{ fontSize: 19 }}>
                                &nbsp;&nbsp;(" الموقع"). تخضع هذه الشروط للتغيير من قِبل شركة طموح
                                للتسويق والخدمات الإلكترونية المحدودة (يشار إليها باسم "ايزون
                                أو&nbsp;
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>ezone</span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                " &nbsp;أو "الشركة" أو "نحن" أو "لنا"، "خاصتنا" كما قد يتطلب السياق)
                                دون كتابة مسبقة إشعار في أي وقت ، وفقًا لتقديرنا الخاص. ستكون أي
                                تغييرات على هذه الشروط سارية المفعول اعتبارًا من "تاريخ آخر تحديث"
                                المشار إليه في هذا الموقع. يجب عليك مراجعة هذه الشروط قبل شراء أي
                                منتج أو خدمات متوفرة من خلال هذا الموقع. استمرار استخدامك لهذا
                                الموقع بعد "تاريخ آخر تحديث" سيشكل موافقتك على هذه التغييرات واقرارك
                                بهـا.
                            </span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                تعد هذه الشروط جزءًا لا يتجزأ من شروط وأحكام استخدام الموقع
                                الإلكتروني وتطبيقات الهاتف المحمول والتي تنطبق بشكل عام على استخدام
                                موقعنا. يجب عليك أيضًا مراجعة سياسة خصوصية موقع الويب وسياسة خصوصية
                                تطبيقات الهاتف المحمول بعناية قبل تقديم طلب للمنتجات أو الخدمات من
                                خلال هذا الموقع
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                        </li>
                    </ul>
                </li>
                <li className="mt-2">
                    <span style={{ lineHeight: "150%", fontSize: 19 }}>
                        قبول الطلب وإلغائه
                    </span>
                    <ul style={{ listStyleType: "disc" }}>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                &nbsp;أنت توافق على أن طلبك عبارة عن عرض لشراء جميع المنتجات
                                والخدمات المدرجة في طلبك بموجب هذه الشروط. وإنـه يجب قبول جميع
                                الطلبات من طـرفـنـا أولاً وإلا فلن نكون ملزمين ببيع المنتجات أو
                                الخدمات لك. قد نختار عدم قبول الطلبات وفقًا لتقديرنا الخاص ، حتى بعد
                                أن نرسل لك رسالة تأكيد بالبريد الإلكتروني تحتوي على رقم الطلب
                                وتفاصيل العناصر التي طلبتها
                            </span>
                            <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                                .
                            </span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                توصي شركة طموح للتسويق والخدمات الإلكترونية المحدودة ((&nbsp;
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>ezone.ly</span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                &nbsp;عملائنا بتجنب الطلبات ومنتجات الشحن المدرجة في قائمة المواد
                                المحظورة قانوناً ، والشحنات و الطلبات التي تحتوي على منتجات وعناصر
                                محظورة بموجب القوانين في بلد المنشأ أو أو بلد الإستخدام أو الوجهة
                                المرسل اليها أو الجهة المرسل عبرآراضيها حيث سوف تحتفظ بها وتقوم
                                بمصادرتها الجهات الضبطية وسلطات الجمارك وسيتم إلغاء الطلب.
                            </span>
                        </li>
                    </ul>
                </li>
                <li className="mt-2">
                    <span style={{ lineHeight: "150%", fontSize: 19 }}>
                        الأسعار وشروط الدفع
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                    <ul style={{ listStyleType: "disc" }}>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                تخضع جميع الأسعار والخصومات والعروض الترويجية المنشورة على هذا
                                الموقع للتغيير دون إشعار. سيكون السعر الذي يتم تحصيله مقابل منتج أو
                                خدمة هو السعر المُعْلَـن عنه على هذا الموقع وقت تقديم الطلب ، مع
                                مراعاة شروط أي عروض ترويجية أو خصومات قد تكون قابلة للتطبيق. سيتم
                                تحديد السعر الذي تم تحصيله بوضوح في البريد الإلكتروني لتأكيد الطلب.
                                لن يتم تطبيق زيادات الأسعار إلا على الطلبات المقدمة بعد وقت الزيادة.
                                الأسعار المعلنة لا تشمل الضرائب أو الرسوم ، مثل الشحن والتأمين
                                والمُـناولة والجمارك والتسليم. ستتم إضافة جميع هذه الضرائب والرسوم
                                الأخرى إلى السعر الإجمالي الخاص بك وسيتم تفصيلها في عربة التسوق
                                الخاصة بك وفي البريد الإلكتروني لتأكيد الطلب. نسعى جاهدين لعرض
                                معلومات دقيقة عن الأسعار؛ ومع ذلك ، قد نرتكب، في بعض الأحيان، أخطاء
                                مطبعية غير مقصودة أو عدم دقة أو سهو يتعلق بالتسعير والتوافر. ونحتفظ
                                بالحق في تصحيح أي أخطاء أو عدم دقة أو سهو في أي وقت وإلغاء أي طلبات
                                تنشأ عن مثل هذه الحوادث
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                أنت تدرك أنه قد تكون هناك رسوم إضافية يتم تحمُّلـها بعد تقديم الطلب
                                وقبل تسليم البضائع / الخدمات. يتم تحـمُّـل هذه الرسوم الإضافية في
                                حالة وجود زيادة في التكاليف من خدمات الطرف الثالث، مثل الشحن
                                والتأمين والجمارك والتسليم. في حالة تحـمُّـل رسوم إضافية ، سوف نخطرك
                                بما إذا كنت لا تزال ترغب في متابعة المعاملة ودفع الرسوم الإضافية
                                لتسليم الطلب. إذا اخترت إلغاء طلبك في هذه المرحلة ، فسنزودك برصيد
                                عميل في حسابك داخل منصتنا. سيتضمن مبلغ ائتمان العميل الضرائب أو
                                الرسوم التي تم دفعها في وقت إجراء الطلب.
                            </span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                تخضع شروط الدفع لتقديرنا وحدنا ، وما لم نتفق على خلاف ذلك كتابةً ،
                                يجب أن نتسلم المبلغ المطلوب قبل قبولنا للطلب. نقبل بطاقات الائتمان
                                المعتمدة وطرق الدفع الأخرى لجميع المشتريات. وعليك كمشتري أن تقر
                                وتضمن ما يلي: (1) معلومات بطاقة الائتمان التي تقدمها لنا حقيقية
                                وصحيحة وكاملة ، (2) أنك مخول باستخدام بطاقة الائتمان هذه للشراء ،
                                (3) وإن شركة الإئتمان التي تقوم بتغطيتك تتعهد بتسديد الرسوم المطلوبة
                                منك، و (4) ستدفع الرسوم التي تـحمَّلتها بالأسعار المُعلنة ، بما في
                                ذلك رسوم الشحن وتأمين الشحن والمُناولة والجمارك ورسوم التسليم وجميع
                                الضرائب المطبقة ، إن وجدت ، بغض النظر عن المبلغ المذكور في الموقع في
                                وقت طلبك
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                        </li>
                    </ul>
                </li>
                <li className="mt-2">
                    <span style={{ lineHeight: "150%", fontSize: 19 }}>
                        الشحنات، التوصيل ، العنوان وخطر الخسارة.
                    </span>
                    <ul style={{ listStyleType: "disc" }}>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                سوف نقوم بالترتيب لشحن المنتجات إليك. يرجى التحقق من صفحة المنتج
                                الفردية للحصول على خيارات تسليم محددة. ستدفع جميع رسوم الشحن
                                والمُناولة ما لم ينص على خلاف ذلك في تأكيد الطلب
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                ينتقل إليك العنوان ومخاطر الخسارة عند نقلنا للمنتجات إلى شركة النقل
                                / التسليم. مواعيد الشحن والتسليم تقديرية فقط ولا يمكن ضمانها. نحن
                                لسنا مسؤولين عن أي تأخير في الشحنات
                            </span>
                        </li>
                    </ul>
                </li>
                <li className="mt-2">
                    <span style={{ lineHeight: "150%", fontSize: 19 }}>
                        الإستبدال أو الترجيع
                    </span>
                    <ul style={{ listStyleType: "disc" }}>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                كل المبيعات نهائية. نحن نقبل المرتجعات للاستبدال، للعناصر التالفة
                                أثناء النقل أو أثناء عملية التسليم. لكي تكون مؤهلاً للاستبدال، يجب
                                عليك أولاً مراسلتنا عبر البريد الإلكتروني في غضون 3 ساعات من تسليم
                            </span>
                            <span style={{ fontSize: 19 }}>&nbsp;</span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                <a href="mailto:info@ezone.ly">
                                    <span style={{ lineHeight: "150%", color: "windowtext" }}>
                                        العناصر
                                    </span>
                                    <span style={{ lineHeight: "150%", color: "windowtext" }}>
                                        info@ezone.ly
                                    </span>
                                </a>
                                &nbsp;
                            </span>
                            <span>&nbsp;</span>لتنبيهنا بالضرر ، والتقاط صورة للعنصر (العناصر)
                            التالفة ، ثم إعادة العناصر التالفة ، وبمجرد استلام طلبك والتحقيق فيه ،
                            سنرسل إليك بريدًا إلكترونيًا لإعلامك بالموافقة على طلبك أو رفضه ، قد
                            تستغرق هذه العملية ما يصل إلى 14 يوم عمل. إذا تمت الموافقة عليك ،
                            فستتم معالجة الاستبدال<span>.</span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                عند استلام المنتج التالف ، سنقوم بشحن بديل ، إذا كان ذلك متاحًا. إذا
                                لم يكن البديل متاحًا ، فسنزودك برصيد العميل في حسابك داخل منصتنا.
                                سيتضمن مبلغ ائتمان العميل الضرائب أو الرسوم التي تم دفعها في وقت
                                إجراء الطلب
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                أنت تدرك أنك مسؤول عن رسوم الشحن (وربما الرسوم الإضافية) لإعادة
                                المنتجات إلى الشركة
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>.</span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                لإرجاع المنتجات بسبب وصولها تالفة ، يجب عليك الاتصال بالبريد
                                الإلكتروني بقسم المرتجعات لدينا
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>&nbsp;</span>
                            <span style={{ lineHeight: "150%" }}>
                                <a href="mailto:info@ezone.ly">
                                    <span style={{ lineHeight: "150%", color: "windowtext" }}>
                                        على
                                    </span>
                                    <span
                                        style={{
                                            lineHeight: "150%",
                                            fontSize: "12.0pt",
                                            color: "windowtext",
                                            textDecoration: "none"
                                        }}
                                    >
                                        &nbsp;info@ezone.ly
                                    </span>
                                </a>
                                &nbsp;
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                للحصول على رقم ترخيص إرجاع البضائع
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                &nbsp;("RMA")&nbsp;
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                قبل شحن منتجك لن يتم قبول أي إرجاع من أي نوع بدون رقم&nbsp;
                            </span>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                &nbsp;("RMA").&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ lineHeight: "150%", fontSize: 19 }}>
                                أنت مسؤول عن جميع رسوم الشحن والمُناولة على المنتجات المُرتجعة ما لم
                                ينص على خلاف ذلك. كما إنك تتحمل مخاطر الخسارة أثناء الشحن. لذلك،
                                نوصي بشدة بأن تقوم بالتأمين الكامل على شحنتك المرتجعة ضد الفقد أو
                                التلف وأن تستخدم شركة نقل يمكنها تزويدك بإثبات التسليم لحمايتك ،
                                وتتم معالجة المبالغ المستردة في غضون (14) يومًا تقريبًا من استلامنا
                                للبضائع الخاصة بك
                            </span>
                            <span
                                style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}
                            >
                                .
                            </span>
                        </li>
                    </ul>
                </li>
            </ol>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        ضمان الشركة المصنعة وإخلاء المسؤولية
                    </span>
                </strong>
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        .
                    </span>
                </strong>
            </p>
            <ol style={{ listStyleType: "decimal" }}>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        تقدم الشركة بنودها من خلال أطراف ثالثة مستقلة. لا يوجد ضمان أو تمثيل
                        فيما يتعلق بهذه الخدمات أو المنتجات. لن تتحمل الشركة أو الشركات التابعة
                        لنا بأي حال من الأحوال المسؤولية عن أي من هذه الخدمات أو المنتجات
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        إلى الحد الأقصى الذي يسمح به القانون ، لا تقدم الشركة أي إقرارات أو
                        ضمانات بشأن صحة أو دقة أو صحة أو موثوقية أو جودة أو استقرار أو اكتمال أو
                        حداثة أي معلومات تتعلق بالمعلومات. لا تتعهد الشركة أو تضمن أن التصنيع أو
                        الاستيراد أو التصدير أو التوزيع أو العرض أو الشراء أو البيع و / أو
                        استخدام المنتجات أو الخدمات المقدمة أو المعروضة على المواقع لا تنتهك أي
                        حقوق لطرف ثالث ؛ ولا تقدم الشركة أي إقرارات أو ضمانات من أي نوع فيما
                        يتعلق بأي منتج أو خدمة معروضة أو متاحة على المواقع
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        تحدد بعض السلطات القضائية أو لا تسمح بإخلاء المسؤولية عن الشروط
                        والضمانات الضمنية أو والضمانات الأخرى، لذلك قد لا ينطبق عليك إخلاء
                        المسؤولية المذكور أعلاه
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        أنت تُـقِّـر أننا لسنا مسؤولين ، تحت أي ظرف من الظروف ، عن أي خرق للضمان
                        أو حالة جودة المنتج أو عن أي أضرار ناتجة عن إخفاق الشركة المصنعة في
                        الوفاء بالتزاماتها تجاه التزاماتها
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
            </ol>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        تحديد المسؤولية
                    </span>
                </strong>
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        .
                    </span>
                </strong>
            </p>
            <ol style={{ listStyleType: "decimal" }}>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        إلى الحد الأقصى الذي يسمح به القانون ، تخلي الشركة مسؤوليتها عن جميع
                        الضمانات ، الصريحة أو الضمنية &nbsp;بما في ذلك ، على سبيل المثال لا
                        الحصر ، الضمانات الضمنية لقابلية التسويق والملاءمة لغرض معين. لن نكون
                        مسؤولين بأي حال من الأحوال تجاهك أو تجاه أي طرف ثالث عن الأضرار اللاحقة
                        أو غير المباشرة أو العرضية أو الخاصة أو النموذجية أو التأديبية أو
                        التدميرية ، أو الأرباح أو الإيرادات المفقودة أو الانخفاض في القيمة ،
                        الناجم عن أو الناتج عن أو الاتصال بأي خرق لهذه الشروط ، بصرف النظر عن
                        (أ) ما إذا كان من المتوقع حدوث مثل هذه الأضرار ، (ب) سواء تم إخطارنا
                        بإمكانية حدوث مثل هذه الأضرار أم لا ، و (ج) النظرية القانونية أو العادلة
                        &nbsp;بناءً على الدعوى
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        مسؤوليتنا الوحيدة والكاملة ، لأي سبب ، يجب أن تـقتصر على التعويض الوحيد
                        والحصري لك لأي سبب مهما كان ، على المبلغ الفعلي الذي دفعته مقابل
                        المنتجات والخدمات التي طلبتها من خلال الموقع
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        أنت توافق بموجب هذا على تعويض وحفظ الشركة والشركات التابعة لنا والمديرين
                        والموظفين والعاملين، من أي وجميع الخسائر والأضرار والمطالبات والمسؤوليات
                        (بما في ذلك التكاليف القانونية المتعلقة بالقضايا أو بشكل غير مباشر ،
                        كنتيجة لأية مطالبات تم تأكيدها من قبل أطراف ثالثة فيما يتعلق بالمنتجات
                        المعروضة. أنت توافق منذ الآن على أن الشركة ليست مسؤولة ولا تتحمل أي
                        مسؤولية تجاهك، عن أي مادة منشورة من قبل الآخرين ، بما في ذلك المواد
                        التشهيرية أو العدوانية أو غير المشروعة وأن مخاطر الأضرار الناجمة عن هذه
                        المواد بالكامل. تحتفظ الشركة بالحق ، على نفقتها الخاصة ، في تحمل الدفاع
                        الحصري والتحكم في أي مسألة أخرى تخضع للتعويض من جانبك ، وفي هذه الحالة
                        يجب أن تتعاون مع الشركة في تأكيد أي دفاع متاح
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        .
                    </span>
                </li>
            </ol>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        الإعفاء
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp; في حالة وجود نزاع مع أي طرف في المعاملة (أي الشركات
                    المُصِّنعة) ، فإنك توافق على إخلاء سبيل الشركة (ووكلائنا والشركات التابعة
                    لنا والمديرين والمسؤولين والموظفين) وتعويضهم من جميع المطالبات والطلبات ،
                    الإجراءات والتكاليف والنفقات والأضرار (بما في ذلك على سبيل المثال لا الحصر
                    أي أضرار فعلية أو خاصة أو عرضية أو تبعية) تنشأ عن أو فيما يتعلق بهذا
                    النزاع أو المعاملة. عند الدخول في هذا الإعفاء ، فإنك تتنازل صراحةً عن أي
                    حماية (سواء كانت قانونية أو غير ذلك) من شأنها أن تحد من تغطية هذا الإعفاء
                    لتشمل فقط تلك المطالبات التي قد تعرفها أو تشك في وجودها لصالحك في وقت
                    الموافقة على هذا الإعفاء
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        البضائع غير المخصصة لإعادة البيع أو التصدير
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        &nbsp; &nbsp;&nbsp;
                    </span>
                </strong>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    أنت تقر وتضمن أنك تشتري منتجات أو خدمات من الموقع لاستخدامك الخاص فقط وليس
                    لإعادة البيع أو التصدير. كما أنك تقر وتتعهد أيضًا بأن جميع المشتريات مخصصة
                    للتسليم النهائي
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        الخصوصية
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "11.25pt",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    نحن نحترم خصوصيتك ونلتزم بحمايتها. تحكم سياسة خصوصية موقع الويب&nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp;
                </span>
                <a href="mailto:info@ezone.ly">
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>ezone.ly</span>
                </a>
                <span>&nbsp;</span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    وسياسة خصوصية تطبيقات الهاتف المحمول معالجة جميع البيانات الشخصية التي يتم
                    جمعها منك فيما يتعلق بشراء المنتجات أو الخدمات من خلال الموقع
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        &nbsp;
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "right",
                    lineHeight: "150%",
                    marginTop: "0in"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        القوة القاهرة
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp; لن نتحمل أي مسؤولية تجاهك ، ولن نعتبر أننا قد تخلفنا عن هذه
                    الشروط أو انتهكناها أوعن أي فشل أو تأخير في أدائنا بموجب هذه الشروط عندما
                    وإلى الحد الذي يكون فيه هذا الإخفاق أو التأخير ناتجًا عن أفعال أو ظروف
                    خارجة عن سيطرتنا المعقولة ، بما في ذلك ، على سبيل المثال لا الحصر: حـوادث
                    متعلقة بالـقـضاء والـقدر مثل الفيضانات والحرائق والزلازل والانفجارات
                    والإجراءات الحكومية والحرب والغزو أو الأعمال العدائية أو العمليات المسلحة
                    (سواء تم إعلان الحرب أم لا) ، والتهديدات أو الأعمال الإرهابية ، وأعمال
                    الشغب أو غيرها من الأعمال المدنية الاضطرابات أو الطوارئ الوطنية أو الثورة
                    أو التمرد أو الأوبئة أو الإغلاق أو الإضرابات أو النزاعات العمالية الأخرى
                    (سواء كانت متعلقة بالقوة العاملة لدينا أم لا)، أو القيود أو التأخير الذي
                    يؤثر على شركات النقل أو عدم القدرة أو التأخير في الحصول على الدعم وكل
                    مايندرج ضمن القوة القاهرة أو الظروف الطارئة .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        القانون الحاكم والاختصاص القضائي
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    تخضع جميع المسائل الناشئة عن هذه الشروط أو المتعلقة بها ويتم تفسيرها وفقًا
                    للقوانين واللوائح والتعميمات الليبية المعمول بها فيها دون تفعيل أي خيار أو
                    تعارض بين أحكام القانون أو القواعد ،&nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%" }}>
                    وتسوى الخلافات التي تنشأ بين الطرفين بالطرق الودية ، ويختص القضاء الليبي
                    بالنظر في المنازعات التي قد تنشأ بين الطرفين
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%" }}>&nbsp;.&nbsp;</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        التنازل
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لن تتنازل عن أي من حقوقك أو تفوض أيًا من التزاماتك بموجب هذه الشروط دون
                    موافقة كتابية مسبقة منا. يعتبر أي تنازل أو تفويض مزعوم بالمخالفة لهذا
                    القسم باطلاً وغـير ذي مـوضوع. ولا يعفيك أي تنازل أو تفويض من أي من
                    التزاماتك بموجب هذه الشروط ، ولا يشكل إخفاقنا أو تأخيرنا في ممارسة أو
                    إنفاذ أي حق أو حكم من هذه الشروط تنازلاً عن الإنفاذ المستقبلي لذلك الحق أو
                    الحُكم. لن يكون التنازل عن أي حق أو حُكم ساريًا إلا إذا كان كتابيًا
                    وموقعًا من قبل ممثل مفوض حسب الأصول من شركة الموقع للخدمات والتجارة
                    الإلكترونية المحدودة.
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        عدم وجود أطراف ثالثة مستفيدة
                    </span>
                </strong>
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        .
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لا تهدف هذه الشروط إلى منح أي حقوق أو تعويضات لأي شخص أو كيان آخر غيرك.
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        الإخطارات
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp; من أجلك أنت. قد نقدم لك أي إشعار بموجب هذه الشروط عن طريق:
                    (1) إرسال رسالة إلى عنوان البريد الإلكتروني الذي تقدمه لنا والموافقة على
                    استخدامنا ؛ أو (2) بالنشر على الموقع. ستصبح الإشعارات المرسلة عبر البريد
                    الإلكتروني سارية عندما نرسل البريد الإلكتروني وستكون الإشعارات التي نقدمها
                    عن طريق النشر سارية عند النشر وتقع على عاتقك مسؤولية الحفاظ على تحديث
                    عنوان بريدك الإلكتروني بالنسبة لنا .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لإرسال إشعار إلينا بموجب هذه الشروط ، يجب عليك الاتصال بنا على النحو
                    التالي: (1) عن طريق التسليم الشخصي أو البريد السريع المـرسل ليلاً بصحبة
                    ساعي بريد أو البريد المُسَّجل أو المُعتمد إلى الموقع على عنوان
                </span>
            </p>
            <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        color: "#222222",
                        marginLeft: "11.25pt",
                        lineHeight: "150%"
                    }}
                >
                    <span style={{ color: "windowtext" }}>
                        <a href="mailto:info@ezone.ly">
                            <span style={{ fontSize: 19, lineHeight: "150%" }}>
                                info@ezone.ly
                            </span>
                        </a>
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "windowtext" }}>
                        &nbsp;
                    </span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        marginLeft: "11.25pt",
                        lineHeight: "150%"
                    }}
                >
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>
                        شركة طموح للتسويق والخدمات الإلكترونيةذ.م.م
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>.</span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        marginLeft: "11.25pt",
                        lineHeight: "150%"
                    }}
                >
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>
                        مبني ريم البوادي – الدور الرابع- , منطقة السياحية – ابونواس - طرابلس –
                        ليبيا
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>&nbsp;</span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        marginLeft: "11.25pt"
                    }}
                >
                    <span style={{ fontSize: 19 }}>موبايل : 8829999 91 218+</span>
                    <span style={{ fontSize: 19 }}>&nbsp;</span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        marginLeft: "11.25pt"
                    }}
                >
                    <span style={{ fontSize: 19 }}>موبايل : 8829999 92 218+</span>
                    <span style={{ fontSize: 19 }}>&nbsp;</span>
                </li>
            </ul>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; يجوز لنا تحديث العنوان لإشعاراتنا عن طريق نشر إشعار على هذا الموقع.
                    ستكون الإشعارات المقدمة عن طريق التسليم الشخصي سارية على الفور ستكون
                    الإشعارات المقدمة من قبل شركة النقل الليلية سارية المفعول بعد يوم عمل واحد
                    من إرسالها. تصبح الإشعارات المقدمة عن طريق البريد المسجل أو المعتمد سارية
                    المفعول بعد 14 يوم عمل بعد إرسالها
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        قابلية الفصل
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    إذا كان أي بند من هذه الشروط غير صالح أو غير قانوني أو باطل أو غير قابل
                    للتنفيذ ، فسيتم اعتبار هذا الحكم منفصلاً عن هذه الشروط ولن يؤثر على صلاحية
                    أو قابلية تنفيذ الأحكام المتبقية من هذه الشروط
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        كامل الاتفاق
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    ستعتبر هذه الشروط وشروط وأحكام الاستخدام الخاصة بموقعنا على الويب وتطبيقات
                    الهاتف المحمول وسياسة خصوصية الموقع الإلكتروني وسياسة الخصوصية الخاصة
                    بتطبيق الهاتف المحمول بمثابة الاتفاقية النهائية والمتكاملة بينك وبيننا
                    بشأن الأمور الواردة في هذه الشروط.
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp;
                </span>
            </p>
            <h1
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 32,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    شــروط وأحـكـام الإستخدام - الموقع والتطبيق
                </span>
            </h1>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    قبول شروط وأحكام الإستخدام
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; تشكل شروط وأحكام استخدام موقع الويب وتطبيقات الهاتف المحمول هذه
                    لـ&nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%" }}>www.ezone.ly</span>
                <span style={{ fontSize: 19, lineHeight: "150%" }}>
                    &nbsp;
                    <span style={{ color: "black" }}>
                        &nbsp;و / أو تطبيق الهاتف المحمول اتفاقية قانونية ويتم إبرامها بينك وبين
                        شـركـة طموح للتسويق والخدمات الإلكترونية م ("الشركة"، "نحـن" "لنا").
                        تحكم الشروط والأحكام التالية ، جنبًا إلى جنب مع أي مستندات و / أو شروط
                        إضافية يتم تضمينها صراحةً بالإشارة إليها (يشار إليها مجتمعة باسم "الشروط
                        والأحكام") ، تحـكـم وصولك إلى واستخدامك لمـوقع الـشركـة، بما في ذلك أي
                        محتوى ووظائف وخدمات مقدمة على أو من خلال&nbsp;
                    </span>
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%" }}>www.ezone.ly</span>
                <span style={{ fontSize: 19, lineHeight: "150%" }}>
                    &nbsp;
                    <span style={{ color: "black" }}>
                        (الموقع) وتطبيق الهاتف المحمول ("التطبيق").
                    </span>
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;من خلال استخدام موقع الويب أو النقر لقبول الشروط والأحكام ،
                    فإنك تقبل وتوافق على الالتزام بهذه الشروط والأحكام والامتثال لسياسة
                    الخصوصية الخاصة بنا ، المضمنة هنا بالإحالة. إذا كنت لا توافق على هذه
                    الشروط والأحكام أو على سياسة الخصوصية ، يجب عليك عدم الوصول إلى الموقع
                    والتطبيق أو استخدامهما
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;&nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    باستخدام هذا الموقع الإلكتروني وتطبيقنا ، فإنك تقر وتضمن أنك تبلغ سن الرشد
                    القانوني بموجب القانون المعمول به لتكوين عقد ملزم مع الشركة وتلبية جميع
                    متطلبات الأهلية السابقة. إذا كنت لا تفي بجميع هذه المتطلبات فلا يجب عليك
                    الوصول إلى موقع الويب وتطبيقنا أو استخدامه
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    تعديلات على الشروط والأحكام وعلى الموقع والتطبيق
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;نحن نحتفظ بالحق وفقًا لتقديرنا الخاص في مراجعة وتحديث هذه
                    الشروط والأحكام من وقت لآخر. تسري هذه التعديلات منفردة ومجتمعة فور نشرها
                    وتنطبق على كل إمكانيات الوصول إلى الموقع والتطبيق واستخدامهما المستمر. أنت
                    توافق على مراجعة الشروط والأحكام بشكل دوري لتكون على دراية بأي تعديلات من
                    هذا القبيل وسيكون استمرار استخدامك بمثابة موافقتك عليها
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;قد يتم تغيير المعلومات والمواد الموجودة على هذا الموقع
                    الإلكتروني و / أو التطبيق أو سحبها أو إنهاؤها في أي وقت وفقًا لتقديرنا
                    الخاص دون إشعار. ولن نكون مسؤولين، لأي سبب من الأسباب، إذا تـعـذر على
                    المستخدمين الوصول لكل أو لجزء من الموقع أو كـان المـوقع غير متاح في أي وقت
                    أو لأي فترة
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: ".5in",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    textIndent: "-34.3pt",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    استخدامك للموقع الإلكتروني و / أو التطبيق وإعداد الحساب والأمان
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;أمان معلوماتك الشخصية مهم جدًا بالنسبة لنا ونحن نستخدم تدابير
                    مادية وإلكترونية وإدارية مصممة لتأمين معلوماتك الشخصية من الضياع العرضي
                    ومن الوصول غير المصرح به والاستخدام والتعديل والإفشاء
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;تعتمد سلامة وأمن معلوماتك عليك أيضًا. يتحمل المستخدمون
                    مسؤولية الحصول على طريقة الإتصال الخاص بهـم إلى الموقع أو التطبيق كمـا
                    يتعين على المستخدمين التأكد من أن جميع الأشخاص الذين يصلون إلى موقع الويب
                    و / أو التطبيق من خلال اتصال الإنترنت الخاص بالمستخدم على دراية بهذه
                    الشروط والأحكام ويلتزمون بها. قد يتطلب موقع الويب أومحتواه أو بعض أجـزاءه،
                    قد يتطلب تسجيل المستخدم. يعتبر شرط من شروط استخدامك للموقع أن تكون جميع
                    المعلومات التي تقدمها على الموقع الإلكتروني أو التطبيق صحيحة وحديثة وكاملة
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;نقل المعلومات عبر الإنترنت للأسف ليس آمنًا تمامًا. على الرغم
                    من أننا نبذل قصارى جهدنا لحماية معلوماتك الشخصية ، إلا أنه لا يمكننا ضمان
                    أمن معلوماتك الشخصية المنقولة إلى موقعنا الإلكتروني و / أو التطبيق. أي نقل
                    للمعلومات الشخصية يكون على مسؤوليتك الخاصة. ونحن لسنا مسؤولين عن التحايل
                    على أي إعدادات خصوصية أو تدابير أمنية واردة في الموقع و / أو التطبيق
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;إن توفير معلومات التسجيل وأي عمليات إرسال تقوم بها إلى موقع
                    الويب و / أو التطبيق من خلال أي وظيفة مثل التطبيقات وغرف الدردشة والبريد
                    الإلكتروني ولوحات الرسائل وصفحات الويب الشخصية أو مجموعات الاهتمامات
                    والملفات الشخصية والمنتديات ولوحات الإعلانات وغيرها مثل هذه الوظائف
                    (مجتمعة ، "الوظائف التفاعلية") تشكل موافقتك على جميع الإجراءات التي نتخذها
                    فيما يتعلق بهذه المعلومات بما يتفق مع سياسة الخصوصية الخاصة بنا
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;يجب التعامل مع أي اسم مستخدم أو كلمة مرور أو أي جزء آخر من
                    المعلومات التي تختارها أو يتم توفيرها لك كجزء من إجراءاتنا الأمنية ، على
                    أنها سرية ، ويجب ألا تفصح عنها لأي شخص أو كيان آخر. يجب عليك توخي الحذر
                    عند الوصول إلى حسابك من جهاز كمبيوتر عام أو مشترك حتى لا يتمكن الآخرون من
                    عرض أو تسجيل كلمة المرور الخاصة بك أو المعلومات الشخصية الأخرى. أنت تدرك
                    وتوافق على أنه في حالة توفير حساب لك ، يكون حسابك شخصيًا لك وتوافق على عدم
                    تزويد أي شخص آخر بالوصول إلى هذا الموقع أو التطبيق أو أجزاء منه باستخدام
                    اسم المستخدم أو كلمة المرور أو معلومات الأمان الأخرى. وأنت توافق على
                    إخطارنا فورًا بأي وصول غير مصرح به أو استخدام لاسم المستخدم أو كلمة المرور
                    أو أي خرق آخر للأمن. وأنت توافق أيضًا على التأكد من تسجيل الخروج من حسابك
                    في نهاية كل جلسة. أنت مسؤول عن أي إساءة استخدام لكلمة المرور أو أي وصول
                    غير مصرح به
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;نحن نحتفظ بالحق في أي وقت ومن وقت لآخر ، في تعطيل أو إنهاء
                    حسابك أو أي اسم مستخدم أو كلمة مرور أو مُعِّرف آخـر سواء اخترته أنت أو
                    قدمناه وفقًا لتقديرنا الخاص لأي سبب أو بدون سبب، بما في ذلك أي انتهاك أي
                    شرط من هذه الشروط والأحكام
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;يُحظر عليك محاولة التحايل وانتهاك أمان هذا الموقع و / أو
                    التطبيق ، بما في ذلك ، على سبيل المثال لا الحصر: (أ) الوصول إلى المحتوى
                    والبيانات غير المخصصة لك ؛ (ب) أي محاولة خرق أو اخـتـراق تدابير الأمن و /
                    أو المصادقة غير المصرح بها ؛ (ج) تقييد أو تعطيل عام أو تعطيل الخدمة
                    للمستخدمين أو المضيفين أو الخوادم أو الشبكات؛ (د) النسخ غير المشروع لرأس
                    حزمة
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp;TCP / IP&nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    ؛ (هـ) تعطيل خدمات الشبكة وتعطيل موقع الويب و / أو قدرة مالك التطبيق على
                    مراقبة موقع الويب و / أو التطبيق؛ (و) استخدام أي روبوت أو عنكبوت أو أي
                    جهاز أو عملية أو وسيلة تلقائية أخرى للوصول إلى الموقع لأي غرض ، بما في ذلك
                    مراقبة أو نسخ أي من المواد الموجودة على الموقع؛ (ز) إدخال أي آفات
                    إلكـترونية مثل فيروسات أو أحصنة طروادة أو ديدان أو قنابل منطقية أو مواد
                    أخرى ضارة أو ضارة من الناحية التكنولوجية ؛ (ح) مهاجمة موقع الويب عن طريق
                    هجوم رفض الخدمة ، أو هجوم رفض الخدمة الموزع ، أو الإغراق ، أو التفجير
                    بالبريد ، أو التعطل ؛ و (1) محاولة التدخل في العمل السليم للموقع و / أو
                    التطبيق
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    حقوق الملكية الفكرية والملكية المادية&nbsp;
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;أنت تدرك وتوافق على أن موقع الويب و / أو التطبيق ، ومحتوياته
                    بالكامل وميزاته ووظائفه ، بما في ذلك ، على سبيل المثال لا الحصر ، جميع
                    المعلومات والبرامج والرموز والنص وشاشات العرض والرسومات والصور إلكترونية
                    الفوتوغرافية والفيديو والصوت والتصميم ، العرض والاختيار والترتيب مملوك
                    للشركة أو المرخصين لها أو غيرهم من مقدمي هذه المواد وهي محمية بجميع
                    أشكالها بموجب قوانين الملكية الفكرية بما في ذلك على سبيل المثال لا الحصر
                    حقوق النشر والعلامة التجارية وبراءات الاختراع والأسرار التجارية وأي حقوق
                    ملكية أخرى
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp;.&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;يعد اسم وشعار الشركة وجميع الأسماء والشعارات وأسماء المنتجات
                    والخدمات والتصاميم والصور والشعارات ذات الصلة علامات تجارية للشركة أو
                    الشركات التابعة لها أو المرخصين لها. لا يجوز لك استخدام هذه العلامات دون
                    الحصول على إذن كتابي مسبق من الشركة. الأسماء الأخرى والشعارات وأسماء
                    المنتجات والخدمات والتصاميم والصور والشعارات المذكورة أو التي تظهر على هذا
                    الموقع هي علامات تجارية لأصحابها المعنيين. يشكل استخدام أي من هذه
                    الممتلكات ، باستثناء ما هو مصرح به صراحة ، انتهاكًا لحقوق مالك المـوقع وقد
                    يكون انتهاكًا للقوانين الفيدرالية أو القوانين الأخرى ويمكن أن يُعِّرض
                    المُتعدي لإجراء قانوني
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;لا يجوز لك استخدام موقع الويب أو التطبيق إلا لاستخدامك الشخصي
                    وغير التجاري. لا يجوز لك على الإطلاق ولابأي شكل من الأشكال ولا من خلال
                    وسيط، إعادة إنتاج أو تجميع قاعدة بيانات داخلية بشكل مباشر أو غير مباشر أو
                    توزيع أو تعديل أو إنشاء أعمال مشتقة أو عرض علني أو تنفيذ أو إعادة نشر أو
                    تنزيل أو تخزين أو نقل أي من المواد الموجودة على موقعنا الإلكتروني أو
                    التطبيق ، باستثناء
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>:</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        قد يقوم جهاز الكمبيوتر والمتصفح الخاص بك بتخزين أو تخزين نسخ مؤقتة من
                        المواد التي يتم الوصول إليها وعرضها ؛
                    </span>
                </strong>
            </p>
            <ol style={{ listStyleType: "decimal" }}>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        يجوز طباعة عدد معقول من النسخ للاستخدام الشخصي فقط مع الاحتفاظ بأي
                        إشعارات ملكية بهذا الشأن ، والتي يمكن استخدامها فقط للاستخدام الشخصي غير
                        التجاري والقانوني وليس لمزيد من النسخ أو النشر أو التوزيع من أي نوع على
                        أي وسيط أيا كان .&nbsp;
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        يجوز تنزيل نسخة مستخدم واحد مع أي إشعارات ملكية سليمة ، لاستخدامك الشخصي
                        غير التجاري ، بشرط موافقتك على الالتزام باتفاقية ترخيص المستخدم النهائي
                        الخاصة بنا لمثل هذه التنزيلات .&nbsp;
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        &nbsp;
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        وفي حالة ربط منصات الوسائط الاجتماعية بمحتوى معين على موقعنا الإلكتروني
                        و / أو تطبيقنا ، يمكنك اتخاذ إجراءات مثل ما يسمح به موقعنا على الويب
                        وتطبيقنا ومنصات التواصل الاجتماعي التابعة لجهات خارجية
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .&nbsp;
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        لا يُسمح للمستخدمين بتعديل نُسخ من أي مواد من هذا الموقع أو التطبيق ولا
                        حذف أو تغيير أي حقوق نشر أو علامة تجارية أو إخطارات حقوق الملكية الأخرى
                        من نُسخ المواد من هذا الموقع. لا يجوز لك الوصول أو استخدام أي جزء من
                        الموقع أو أي خدمات أو مواد متاحة من خلال الموقع و / أو التطبيق لأي أغراض
                        تجارية
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        .&nbsp;
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        إذا قمت بطباعة أو نَسخ أو تنزيل أي جزء من موقعنا و / أو تطبيقنا بشكل
                        ينتهك هذه الشروط والأحكام ، فسيتوقف حقك في استخدام الموقع و / أو التطبيق
                        على الفور ويجب عليك ، حسب خيارنا ، إعادة أو إتلاف أي نسخ من المواد التي
                        قمت بإنشائها. ليس لديك أي حق أو ملكية أو مصلحة في أو على موقع الويب و /
                        أو التطبيق أو أي محتوى على موقع الويب و / أو التطبيق ، وجميع الحقوق غير
                        الممنوحة صراحةً محفوظة من قبل الشركة. يعد أي استخدام للموقع و / أو
                        التطبيق غير مسموح به صراحةً بموجب هذه الشروط والأحكام انتهاكًا لهذه
                        الشروط والأحكام وقد ينتهك حقوق الطبع والنشر والعلامات التجارية والملكية
                        الفكرية الأخرى أو قوانين الملكية الأخرى.&nbsp;
                    </span>
                </li>
            </ol>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    شروط الاستخدام وتقديمات المستخدم ومعايير محتوى الموقع
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    كشرط لوصولك واستخدامك ، فإنك توافق على أنه لا يجوز لك استخدام موقع الويب و
                    / أو التطبيق إلا لأغراض مشروعة ووفقًا لهذه الشروط والأحكام
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    تنطبق معايير المحتوى التالية على أي وجميع المحتويات والمواد والمعلومات
                    التي يرسلها المستخدم أو ينشرها أو ينشرها أو يعرضها أو ينقلها (بشكل جماعي ،
                    "إرسال") إلى موقع الويب أو التطبيق أو إلى مستخدمين آخرين أو أشخاص آخرين
                    (إجمالاً، "عمليات إرسال المستخدم") وأي وجميع الوظائف التفاعلية. يجب أن
                    تمتثل أي وجميع عناصر المستخدمين المرسلة لجميع القوانين واللوائح وشروط
                    الخدمة الفيدرالية والإقليمية والمحلية والدولية المعمول بها
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    دون تقييد ما سبق ، فإنك تتعهد وتوافق على أن استخدامك للموقع وأي من عناصر
                    المستخدمين المرسلة لن ينتج عنه
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>:</span>
            </p>
            <ol style={{ listStyleType: "decimal" }}>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        إنتهاك بأي شكل من الأشكال أي قانون أو لائحة فيدرالية أو إقليمية أو محلية
                        أو دولية معمول بها ، بما في ذلك ، على سبيل المثال لا الحصر ، أي قوانين
                        تتعلق بتصدير البيانات أو البرامج أو براءات الاختراع أو العلامات التجارية
                        أو الأسرار التجارية أو حقوق الطبع والنشر أو غيرها من حقوق الملكية
                        الفكرية أو القانونية الحقوق (بما في ذلك حقوق الدعاية وخصوصية الآخرين) أو
                        تحتوي على أي مواد يمكن أن تؤدي إلى أي مسؤولية مدنية أو جنائية بموجب
                        القوانين أو اللوائح المعمول بها أو التي قد تتعارض مع هذه الشروط والأحكام
                        وسياسة الخصوصية الخاصة بنا
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        إنتهاك بأي شكل من الأشكال شروط استخدام أي موقع ويب و / أو تطبيق تابع
                        لجهة خارجية مرتبط بالموقع الإلكتروني و / أو التطبيق ، بما في ذلك على
                        سبيل المثال لا الحصر ، أي موقع ويب تابع لطرف ثالث
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        تضمين أو احتواء أي مواد استغلالية أو فاحشة أو ضارة أو مهددة أو مسيئة أو
                        مضايقة أو بغيضة أو تشهيرية أو جنسية صريحة أو إباحية أو عنيفة أو تحريضية
                        أو تمييزية على أساس العرق أو الجنس أو الدين أو الجنسية أو الإعاقة أو
                        التوجه الجنسي ، أو العمر أو أي سبب آخر محظور قانونًا أو يكون غير مقبول
                        بأي شكل آخر ، يجب أن يتم هذا القرار وفقًا لتقدير الشركة وحدها
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        المشاركة في المطاردة ، ومحاولة استغلال أو إيذاء أي فرد (بما في ذلك
                        القُصَّر) بأي شكل من الأشكال عن طريق تعريضهم لمحتوى غير لائق أو خلاف ذلك
                        أو طلب معلومات شخصية على النحو المحظور بموجب القوانين أو اللوائح أو
                        التعليمات البرمجية المعمول بها
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        تضمين أو تقديم أو المساهمة بأي معلومات خاطئة أو غير دقيقة أو مضللة
                    </span>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        انتحال شخصية أو محاولة انتحال شخصية الشركة أو موظف الشركة أو مستخدم آخر
                        أو أي شخص أو كيان آخر (بما في ذلك ، على سبيل المثال لا الحصر ، استخدام
                        عناوين البريد الإلكتروني أو أسماء الشاشة المرتبطة بأي مما سبق) .&nbsp;
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        نقل أو تدبير إرسال أي إعلانات أو عروض ترويجية أو مبيعات أو تشجيع أي
                        أنشطة تجارية أخرى ، بما في ذلك ، على سبيل المثال لا الحصر ، أي "بريد
                        عشوائي" و "بريد غير مرغوب فيه" و "رسالة متسلسلة" ومسابقات ومسابقات
                        يانصيب وغيرها ترويج المبيعات أو المقايضة أو الإعلان أو أي طلب آخر مشابه
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        تشجيع أي سلوك آخر يقيد أو يمنع أي شخص من استخدام أو الاستمتاع بالموقع
                        الإلكتروني و / أو التطبيق ، أو قد يضر الشركة أو مستخدمي الموقع أو يعرضهم
                        للمسؤولية على النحو الذي نحدده
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        التسبب في إزعاج أو قلق لا داعي له أو من المحتمل أن يزعج أو يحرج أو ينبه
                        أي شخص آخر
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        ترويج لأي نشاط غير قانوني ، أو الدفاع عن أي عمل غير قانوني أو تروج له أو
                        تساعده
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        إعطاء انطباعًا بأن هذه الأعمال نشأت منا أو معتمدة من قبلنا أو أي شخص أو
                        كيان آخر ، إذا لم يكن الأمر كذلك
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
            </ol>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    تقديمات المستخدم: منح الترخيص
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    قد يحتوي موقع الويب و / أو التطبيق على وظائف تفاعلية تسمح بإرسال مشاركات
                    المستخدم على أو من خلال موقع الويب و / أو التطبيق
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .&nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لن يخضع أي من عناصر المستخدمين المُرْسَلة التي ترسلها إلى موقع الويب و /
                    أو التطبيق لأي سرية من قبل الشركة. من خلال تقديم أي "إرسال مستخدم" إلى
                    موقع الويب و / أو التطبيق ، فإنك تمنحنا والشركات التابعة لنا ومقدمي
                    الخدمات ، وكل من المرخص لهم أو المرخص لهم لدينا ، وخلفائهم ، تمنحنا
                    وتتنازل عن حق من حقوق ملكية عالمية ودائمة وغير قابلة للإلغاء، كما تمنحنا
                    ترخيصاً غير حصري لاستخدام و إعادة إنتاج و تعديل وأداء وعرض وتوزيع والإفصاح
                    بطريقة أخرى لأطراف ثالثة عن أي من هذه المواد لأي غرض، وفقًا لإعدادات حسابك
                    و / أو دمج هذه المواد في أي شكل أو وسيط أو تقنية في جميع أنحاء العالم دون
                    تعويض لك. كما أنك تتنازل عن أي حقوق معنوية أو حقوق تأليف أخرى كشرط لإرسال
                    أي من عناصر المستخدمين المرسلة
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    من خلال تـسـلـيم مساهمات المستخدم ، فإنك تقر وتتعهد بأنك تمتلك أو لديك
                    الحقوق اللازمة لإرسال مساهمات المستخدم ولديك الحق في منح الترخيص هنا لنا
                    وللشركات التابعة لنا ومقدمي الخدمات ، ولكل من المرخص لهم ولنا والخلفاء
                    لنا، وتقربالتنازل عن مساهمات المستخدم والامتثال لهذه الشروط والأحكام. كما
                    أنك تقر وتتعهد بأن جميع مساهمات المستخدم تمتثل للقوانين واللوائح المعمول
                    بها ومعايير محتوى الموقع ومساهمات المستخدم المنصوص عليها في هذه الشروط
                    والأحكام
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    وأنت تدرك وتوافق على أنك لست الشركة ولا مديريها أو مسؤوليها أو موظفيها أو
                    وكلائها أو مقدمي الخدمات أو المقاولين أو المرخصين أو الموردين أو الخلفاء ،
                    تتحمل المسؤولية الكاملة عن أي مساهمات للمستخدم تقدمها أو تساهم بها ، وأنت
                    مسؤول مسؤولية كاملة و مسؤول قانونيًا ، بما في ذلك تجاه أي طرف ثالث ، عن
                    هذا المحتوى ودقته. نحن لسنا مسؤولين أو مطالبين قانونًا تجاه أي طرف ثالث عن
                    محتوى أو دقة أي مساهمات يرسلها المستخدم سواء تقدمها أنت أو أي مستخدم آخر
                    للموقع و / أو التطبيق.
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp;
                </span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    مراقبة الموقع والإنفاذ والتعليق والإنهاء
                </span>
            </h2>
            <p
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        يحق للشركة ، دون تقديم إشعارأن تقوم
                    </span>
                </strong>
                <strong>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        :
                    </span>
                </strong>
            </p>
            <ol style={{ listStyleType: "decimal" }}>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        بإزالة أو رفض النشر على موقع الويب و / أو التطبيق ، لأي إضافات مستخدم
                        لأي سبب أو بدون سبب وفقًا لتقديرنا الخاص
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        في جميع الأوقات ،بإتخاذ مثل هذه الإجراءات فيما يتعلق بأي إضافات مستخدم
                        لأن ذلك يعتبر ضروريًا أو مناسبًا وفقًا لتقديرنا الخاص ، بما في ذلك ، على
                        سبيل المثال لا الحصر ، انتهاك موقع الويب و / أو التطبيق ومساهمات
                        المستخدم ومعايير محتوى الموقع أو الشروط والأحكام
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        بإتخاذ الإجراءات القانونية المناسبة ، بما في ذلك ، على سبيل المثال لا
                        الحصر ، الإحالة إلى سلطة إنفاذ القانون أو السلطة التنظيمية ، أو إخطار
                        الطرف المتضرر بأي استخدام غير قانوني أو غير مصرح به للموقع و / أو
                        التطبيق. دون تقييد ما سبق ، يحق لنا التعاون الكامل مع أي سلطات إنفاذ
                        قانون أو أمر محكمة يطلب منا أو يوجهنا بالكشف عن هوية أو معلومات أخرى لأي
                        شخص ينشر أي مواد على أو من خلال الموقع و / أو التطبيق
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .&nbsp;
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        بإنهاء أو تعليق وصولك إلى كل أو جزء من الموقع الإلكتروني و / أو التطبيق
                        لأي سبب أو بدون سبب ، بما في ذلك على سبيل المثال لا الحصر ، أي انتهاك
                        لهذه الشروط والأحكام
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        أنت تتنازل وتــدرء الضرر عن الشركة والمدراء والمسؤولين والموظفين
                        والوكلاء ومقدمي الخدمات والمقاولين والمُرخِّصين والمُرخَّصين والموردين
                        والمستفيدين من أي وجميع المطالبات الناشئة عن أي دعوى مقدمة من قبل أي
                        طرف. لأي تحقيقات تجريها الشركة أو هذه الأطراف أو سلطات إنفاذ القانون
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
                <li>
                    <span style={{ lineHeight: "150%", fontSize: "14.0pt", color: "black" }}>
                        ليس لدينا أي التزام أو أي مسؤولية تجاه أي طرف لمراقبة الموقع الإلكتروني
                        و / أو التطبيق أو استخدامه ، ولا نتعهد ولا يمكننا أن نتعهد بمراجعة
                        المواد التي ترسلها أنت أو المستخدمون الآخرون إلى الموقع و / أو التطبيق.
                        لا يمكننا ضمان الإزالة السريعة للمواد المرفوضة بعد نشرها ولا نتحمل أي
                        مسؤولية عن أي إجراء أو تقاعس عن العمل فيما يتعلق بعمليات النقل أو
                        الاتصالات أو المحتوى المقدم من قبل أي مستخدم أو طرف ثالث ، مع مراعاة
                        القوانين المعمول بها
                    </span>
                    <span style={{ lineHeight: "150%", color: "black", fontSize: 19 }}>
                        .
                    </span>
                </li>
            </ol>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لا تعـتمـد على التالي
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>:</span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; يتم توفير المحتوى الموجود على موقعنا الإلكتروني و / أو التطبيق
                    لأغراض المعلومات العامة فقط. لا يُقصد به أن يكون بمثابة نصيحة يجب أن تعتمد
                    عليها يجب عليك الحصول على مشورة أكثر تحديدًا أو احترافية قبل اتخاذ أو
                    الامتناع عن أي إجراء أو تقاعس عن العمل على أساس المحتوى الموجود على موقعنا
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; على الرغم من أننا نبذل جهودًا معقولة لتحديث المعلومات الموجودة على
                    موقعنا الإلكتروني و / أو التطبيق ، فإننا لا نقدم أي تعهدات أو تأمينات أو
                    ضمانات ، سواء كانت صريحة أو ضمنية ، بأن المحتوى الموجود على موقعنا و / أو
                    تطبيقنا دقيق أو كامل أو مُحَّدث. إن استخدامك للموقع و / أو التطبيق يكون
                    على مسؤوليتك الخاصة ولا تتحمل الشركة ولا مديروها أو مسؤوليها أو موظفوها أو
                    وكلاءها أو مقدمو الخدمات أو المقاولون أو المرخصون أو المرخص لهم أو
                    الموردون أو الخلفاء أي مسؤولية من أي نوع عن استخدامك من هذا الموقع و / أو
                    التطبيق
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; قد يتضمن هذا الموقع و / أو التطبيق محتوى مقدمًا من قبل أطراف ثالثة
                    ، بما في ذلك من مستخدمين ومرخصين آخرين. جميع البيانات و / أو الآراء
                    المُعَّـبر عنها في أي محتوى تابع لجهة خارجية ، بخلاف المحتوى المُقَـدم من
                    قِـبَل الشركة، هي فقط آراء ومسؤولية الشخص أو الكيان الذي يقدم هذه المواد.
                    هذه المواد لا تعكس بالضرورة رأي الشركة
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    الخصوصية
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;من خلال تقديم معلوماتك الشخصية واستخدام موقعنا و / أو تطبيقنا
                    ، فإنك توافق على جمع واستخدام وإعادة إنتاج واستضافة ونقل والكشف عن أي
                    عمليات إرسال لمحتوى المستخدم وفقًا لسياسة الخصوصية الخاصة بنا ، الموجودة
                    على موقعنا الإلكتروني و / أو التطبيق. كما نراه ضروريًا لاستخدام الموقع و /
                    أو التطبيق
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp; باستخدام هذا الموقع و / أو التطبيق ، فإنك توافق على استخدام
                    ملفات تعريف الارتباط التي تسمح للخادم باستدعاء الطلبات السابقة أو التسجيل
                    و / أو عناوين
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp;IP&nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لتحليل أنماط استخدام الموقع و / أو التطبيق. يمكنك ضبط المُسْتَعْرض الخاص
                    بك لإعلامك قبل أن تتلقى ملف تعريف ارتباط ، مما يمنحك الفرصة لتقرر قبوله.
                    يمكنك أيضًا ضبط متصفحك لإيقاف تشغيل ملفات تعريف الارتباط. ومع ذلك ، إذا
                    قمت بذلك ، فقد لا تعمل بعض مناطق موقع الويب و / أو التطبيق بشكل كافٍ
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    عمليات الشراء عبر الإنترنت
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    تخضع جميع الطلبات أو المشتريات أو المعاملات الخاصة ببيع البضائع التي تتم
                    باستخدام هذا الموقع و / أو التطبيق لشروط وأحكام البيع ، والمدرجة أيضًا في
                    هذه الشروط والأحكام
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    قد يتم تطبيق شروط وأحكام إضافية على أجزاء أو ميزات من هذا الموقع ويتم
                    دمجها بموجب هذه الشروط والأحكام بالإشارة إليها
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    القيود الجغرافية
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    يقع مالك الموقع و / أو التطبيق في ليبيا. نحن نقدم هذا الموقع و / أو
                    التطبيق للاستخدام فقط من قبل الأشخاص الموجودين في ليبيا. هذا الموقع
                    والتطبيق غير مخصصين للاستخدام في أي ولاية قضائية حيث لا يُسمح باستخدامه.
                    إذا قمت بالوصول إلى الموقع والتطبيق من خارج ليبيا ، فإنك تقوم بذلك على
                    مسؤوليتك الخاصة وأنت مسؤول عن الامتثال للقوانين المحلية في ولايتك القضائية
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    التنصل من الضمانات
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;إنك تدرك وتوافق على أن استخدامك للموقع الإلكتروني و / أو
                    التطبيق ، ومحتواه ، وأي خدمات أو عناصر موجودة أو يتم الحصول عليها من خلال
                    الموقع الإلكتروني و / أو التطبيق على مسؤوليتك الخاصة. يتم توفير موقع الويب
                    و / أو التطبيق ومحتوياته وأي خدمات أو عناصر موجودة أو تم الحصول عليها من
                    خلال الموقع الإلكتروني و / أو التطبيق على أساس "كما هي" و "كما هو متاح" ،
                    دون أي ضمانات من أي نوع صراحًة أو ضمنيًا بما في ذلك ، على سبيل المثال لا
                    الحصر ، الضمانات الضمنية الخاصة بقابلية التسويق أو الملاءمة لغرض معين أو
                    عدم الانتهاك. ما سبق لا يؤثر على أي ضمانات لا يمكن استبعادها أو تقييدها
                    بموجب القانون المعمول به
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; لا تقدم الشركة ولا صاحبها أو الشركات التابعة لها أو المُساعِـدة لها
                    أو مُديروها أو مُوظفوها أو وكلاءها أو مُقدمو الخدمات أو المتعاقدون أو
                    المُرخِّصون أو المَرخَّصون أو المورِّدون أو المسؤولون عنهم أي ضمانات أو
                    تعهدات لهم على ملاءمة الموقع الإلكتروني و / أو التطبيق أو دقته أو سعره أو
                    توفره أو محتوياته. بدون تحديد ما سبق ، لا الشركة تـؤكد ولا الشركة الأم أو
                    شركائها أو الشَرِكات التابعة لها أو مديريها أو مسؤوليها أو موظفيها أو
                    وكلائها أو مقدمي الخدمات أو المتعاملين أو المُرخِصين أو المُرخَصين أو
                    مورديها أو وكلاءها لتـوفـيـرالمحتوى ، أو أي خدمات أو عناصر تم العثور عليها
                    أو تم الحصول عليها من خلال الموقع الإلكتروني و / أو التطبيق، لا يـؤكـد كل
                    هـؤلاء على دقة أو ثقة أو الخلو من الأخطاء أو الانقطاع ، وسيتم تصحيح العيوب
                    وذلك من خلال موقعنا الإلكتروني و / أو التطبيق ، أو الخادم المتوفرالخالي من
                    الفيروسات أو المكونات الضارة الأخرى
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    .<br />
                    &nbsp;
                </span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لا يمكننا ولا نضمن أو نؤكـد أن الملفات أو البيانات المتاحة للتنزيل من
                    الإنترنت أو الموقع الإلكتروني و / أو التطبيق ستكون خالية من الفيروسات أو
                    الرموز المدمرة الأخرى. أنت وحدك المسؤول تمامًا عن استخدامك للموقع
                    الإلكتروني وأمن الكمبيوتر والإنترنت والبيانات. إلى أقصى حد ينص عليه
                    القانون ، لن نتحمل أي مسؤولية عن أي خسارة أو ضرر ناتج عن هجوم رفض الخدمة ،
                    أو الهجوم الموزع لإنكار الخدمة ، أو التجاوز ، أو الفيضانات المعلوماتية، أو
                    التفجير بالبريد أو إنهيار الخدمة، أو الإصابة بالفيروسات ، أوقـنابل
                    الفيروسات المنطقية ، أو مواد ضارة أخرى تكنولوجيًا والتي قد تؤثر على معدات
                    الكمبيوتر أو برامج الكمبيوتر أو البيانات أو المواد الأخرى الخاصة بالملكية
                    بسبب استخدامك للموقع الإلكتروني و / أو التطبيق أو أي خدمات أو عناصر موجودة
                    على موقع الويب أو التطبيق أو لتنزيلك لأي مادة منشورة عليها ، أو على أي
                    موقع إلكتروني و / أو مرتبط به
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    تحديد المسؤولية
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp; &nbsp;باستثناء الحالات التي يحظر فيها القانون مثل هذه
                    الاستثناءات ، تحت أي ظرف من الظروف ، لن تكون الشركة أو صاحبها أو شركائها
                    أو الـشركات المـسـاعدة لهـا أو الشركات التابعة لها أو مديريها أو مسؤوليها
                    أو موظفيها أو الوكلاء أو مقدمي الخدمات المُـساندة أو المتعهدين أو مقدمي
                    الخدمة نفسهـا، لن يعُتـبـروا مـسـؤولين عن الإهمال الجسيم ، أوالإهمال
                    الظاهري أوالخرق الأساسي أوالأضرار من أي نوع. وبموجب أي نظرية قانونية ، بما
                    في ذلك أي أضرار مباشرة أو غير مباشرة أو خاصة أو عرضية أو لاحقة أو جزائية
                    أو أضرار غير محدودة مُتعلقة بمشاعـرالكراهيةأوفقدان الإيرادات والأرباح
                    أوخسارة الأعمال التجارية أو المدخرات المتوقعة أوفقدان قُـدرة الاستخدام
                    أوفقدان السُمْعة أو البيانات سواء كان ذلك بسبب الضرر (بما في ذلك الإهمال ،
                    أو أي انتهاك آخر) حتى إذا تم تقديم النصح للطرف أو كان لديك سبب لمعرفته،
                    ناشئًا عن أو متصل باستخدامك أو عدم قدرتك على استخدام أو الاعتماد على موقع
                    الويب أو التطبيق أو أي من مواقع الويب المرتبطة المعروفة ب
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp;APP
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    أو مثل هذه المواقع الإلكترونية التابعة لجهات خارجية أخرى ولا أي محتوى أو
                    مواد أو نشر أو معلومات في هذا الموقع حتى إذا كان الطرف قد تم إخطاره أو كان
                    لديه سبب لعلمه
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    التعويض
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;حـتى أقصى حد يسمح به القانون المعمول به ، أنت توافق على
                    الدفاع عن الشركة وتعويضها و توافق على عدم الإضرار بها وبالشركات التابعة
                    لها ، ومديريها ، ومسؤوليها ، وموظفيها ووكلائها ومقدمي الخدمات والمقاولين
                    والمِرخِصين ، والموردين والخلفاء ويتنازل عن أي مطالبات أو مسؤوليات أو
                    أضرار أو أحكام أو جوائز أو خسائر أو تكاليف أو نفقات أو رسوم (بما في ذلك
                    أتعاب المحاماة المعقولة) الناشئة عن أو المتعلقة بخرقك لهذه الشروط والأحكام
                    أو استخدامك لـ موقع الويب والتطبيق ، بما في ذلك ، على سبيل المثال لا الحصر
                    ، عمليات إرسال المستخدم الخاصة بك ، ومواقع الجهات الخارجية ، وأي استخدام
                    لمحتوى الموقع وخدماته ومنتجاته بخلاف ما هو مصرح به صراحةً في هذه الشروط
                    والأحكام
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    القانون والشريعة السائدة
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp; سيخضع موقع الويب و / أو التطبيق وهذه الشروط والأحكام وتفسر
                    وفقًا لأحكام القوانين واللوائح المعمول بها في ليبيا ، دون تفعيل أي خيار أو
                    تعارض بين أحكام القانون أو المبدأ أو القاعدة (سواء كانت قوانين ليبيا أو أي
                    مدينة أو بلدية أخرى) وبغض النظر عن موطنك أو إقامتك أو موقعك الفعلي
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;سيتم رفع أي إجراء عـام أو ناشئ عن أو يتعلق بهذا الموقع و / أو
                    التطبيق ، وبموجب هذه الشروط والأحكام في المحاكم الليبية ، ويخضع كل طرف
                    بشكل لا رجوع فيه إلى المحكمة الحصرية اختصاص هذه المحاكم في أي إجراء أو
                    إجراء من هذا القبيل. أنت تتنازل عن أي وجميع الاعتراضات على ممارسة الولاية
                    القضائية عليك من قبل هذه المحاكم وفي مكان انعقاد هذه المحاكم
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    تـنَازُل
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    لا يُعد الإخفاق في ممارسة ، أو التأخير في ممارسة أي حق أو تعويض أو سُلْطة
                    أو امتياز ناشئ عن هذه الشروط والأحكام بمثابة تَـنَازُل عنها، كما إنـهـا لا
                    تمنع أي ممارسة فردية أو جزئية لأي حق أو تعويض أو سُلْطة أو امتياز بموجب
                    هذه الاتفاقية أو أي ممارسة أخرى، أو ممارسة أي حق أو تعويض أو سُلطة أو
                    امتياز آخر
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    الاستقلالية
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp;إذا كان أي بند أو حكم من هذه الشروط والأحكام غير صالح أو غير
                    قانوني أو غير قابل للتنفيذ في أي مدينة أو منطقة ، فإن مثل هذا البطلان أو
                    الـوضع غير القانوني أو عدم قابلية التنفيذ لن يؤثر على أي بند أو حكم آخر من
                    هذه الشروط والأحكام أو يبطل أو يجعل هذا المصطلح أو الحكم غير قابل للتنفيذ
                    في أي مدينة أو منطقة أخرى
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    اتفاق كامل
                </span>
            </h2>
            <p
                style={{
                    marginRight: "0in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "justify",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    &nbsp; &nbsp; تُـشَّكِل الشروط والأحكام وسياسة الخصوصية الخاصة بنا وشروط
                    وأحكام البيع الاتفاقية الوحيدة والكاملة بينك وبين شركة&nbsp;
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "#222222" }}>
                    شركة طموح للتسويق والخدمات الإلكترونية ذ.م.م
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "#222222" }}>
                    .
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    " فيما يتعلق بالموقع الإلكتروني و / أو التطبيق وتحل محل جميع التفاهمات
                    والاتفاقيات السابقة والمعاصرة والإقرارات والضمانات المكتوبة والشفوية فيما
                    يتعلق بهذا الموضوع
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>.</span>
            </p>
            <h2
                style={{
                    marginRight: "1.7pt",
                    marginLeft: "0in",
                    fontSize: 24,
                    fontWeight: "bold",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    الإبلاغ والاتصال
                </span>
            </h2>
            <p
                style={{
                    marginRight: ".5in",
                    marginLeft: "0in",
                    fontSize: 16,
                    margin: "0in",
                    marginBottom: ".0001pt",
                    textAlign: "right",
                    lineHeight: "150%"
                }}
            >
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                    يتم تشغيل هذا الموقع والتطبيق من قِبَل
                </span>
                <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>:ـ</span>
            </p>
            <ul style={{ marginBottom: "0in", listStyleType: "disc" }}>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        color: "#222222",
                        marginLeft: "11.25pt",
                        lineHeight: "150%"
                    }}
                >
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>
                        شركة طموح للتسويق والخدمات الإلكترونية
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>&nbsp;</span>
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>ذ.م.م</span>
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>.</span>
                </li>
                {/* <li
      style='margin:0in;margin-bottom:.0001pt;text-align:right;font-size:16px;color:#222222;margin-left:11.25pt;line-height:150%;'>
      <span style="color:windowtext;"><a href="mailto:info@ezone.ly"><span
                  style='font-size:19px;line-height:150%;'>info@ezone.ly</span></a></span><span
          style='font-size:19px;line-height:150%;color:windowtext;'>&nbsp;</span>
  </li> */}
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        color: "#222222",
                        marginLeft: "11.25pt",
                        lineHeight: "150%"
                    }}
                >
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>
                        مبني ريم البوادي – الدور الرابع- , منطقة السياحية –ابونواس - طرابلس –
                        ليبيا
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%" }}>&nbsp;</span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        marginLeft: "11.25pt"
                    }}
                >
                    <span style={{ fontSize: 19 }}>موبايل : 8829999 91 218+</span>
                    <span style={{ fontSize: 19 }}>&nbsp;</span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        marginLeft: "11.25pt"
                    }}
                >
                    <span style={{ fontSize: 19 }}>موبايل : 8829999 92 218+</span>
                    <span style={{ fontSize: 19 }}>&nbsp;</span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        color: "#222222",
                        marginLeft: "11.25pt",
                        lineHeight: "150%"
                    }}
                >
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        إذا علمت بسوء استخدام الموقع بما في ذلك السلوك يتصف بالتشهيرأو الـقذف،
                        فيجب عليك إبلاغ الشركة على عنوان البريد
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        &nbsp;
                    </span>
                    <span style={{ color: "black" }}>الإلكتروني</span>
                    <span style={{ color: "black" }}>
                        &nbsp;
                        <a style={{ fontSize: 19 }} href="mailto:info@ezone.ly">
                            info@ezone.ly
                        </a>
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "windowtext" }}>
                        &nbsp;
                    </span>
                </li>
                <li
                    style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        textAlign: "right",
                        fontSize: 16,
                        color: "#222222",
                        marginLeft: "11.25pt",
                        lineHeight: "150%"
                    }}
                >
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        يجب توجيه جميع التعليقات و ردود الأفـعال وطلبات الدعم الفني وغيرها من
                        الاتصالات المتعلقة بالموقع الإلكتروني و / أو التطبيق
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "black" }}>
                        &nbsp;
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "windowtext" }}>
                        إلى
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "windowtext" }}>
                        &nbsp;
                    </span>
                    <span style={{ color: "windowtext" }}>
                        <a style={{ fontSize: 19 }} href="mailto:info@ezone.ly">
                            info@ezone.ly
                        </a>
                    </span>
                    <span style={{ fontSize: 19, lineHeight: "150%", color: "windowtext" }}>
                        &nbsp;
                    </span>
                </li>
            </ul>
        </div>
    );
}