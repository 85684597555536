export default function PrivacyPolicy() {



    return (

        <div className="max-w-5xl mx-auto px-2 py-12">
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 21 }}>
              سياسة الخصوصية وسرية المعلومات للتاجر
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 21, color: "#555555" }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; يرحّب بكم فريق عمل منصة إيزون عبر منصته الإلكترونية ،
            وتتقدّم منصة إيزون بالشكر على ثقتكم بالمنصة، وتفيدكم بأنه حرصاً وادراكها
            منها بأن التاجر له حقوق معيّنة عبر منصة إيزون &nbsp;فإنها تسعى للحفاظ على
            المعلومات الخاصة بالتجار والمستهلكين وفقاً لآلية سياسة الخصوصية وسرية
            المعلومات المعمول بها في منصة إيزون.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; وعليه فإن منصة إيزون تنوّه بأنه وفقاً للعقد المبرم بينكم
            كتجار أو عملاء وبين منصة إيزون فإن هذه الوثيقة تحيطكم علماً بسياسة
            الخصوصية وسرية المعلومات المعمول بها في منصة إيزون{" "}
            <u>وأنه وفقاً للمادة الخامسة عشرة</u> من العقد المبرم فقد أنشأت منصة إيزون
            هذه القواعد “سياسة الخصوصية وسرية المعلومات” لتوضيح وتحديد آلية السرية
            والخصوصية المعمول بها في منصة إيزون الإلكترونية، ويرجى اطلاعكم عليها حيث
            أنكم بولوجكم إلى منصة إيزون وتأسيسكم للمتجر الإلكتروني فإن جميع معلوماتكم
            تخضع لهذه السياسة
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19, fontWeight: "bold" }}>
            المعلومات التي تحصل عليها منصة إيزون وتحتفظ بها في أنظمتها الإلكترونية
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ol style={{ listStyleType: "decimal" }}>
          <li>
            <span>
              المعلومات الشخصية الخاصة بالمستهلك ، كالاسم والبريد الإلكتروني&nbsp;
            </span>
            <span>.</span>
          </li>
          <li>
            <span>
              المعلومات الشخصية الخاصة بالتاجر، كالاسم والعمر والبريد الإلكتروني،
            </span>
            <span>.</span>
          </li>
          <li>
            <span>
              المعلومات الخاصة بالمتجر وكيانه القانوني ، كرقم السجل التجاري وصورة من
              السجل التجاري
            </span>
            <span>.</span>
            <span style={{ fontSize: 19 }}>&nbsp;وترخيص مزاولة النشاط . &nbsp;</span>
          </li>
          <li>
            <span>
              معلومات الدخول الشخصية الخاصة بالمتجر الإلكتروني ، مثل اسم المستخدم
              وكلمة السر والبريد الإلكتروني، والسؤال الخاص باسترجاع كلمة السر وإجابته
            </span>
            <span>.</span>
          </li>
          <li>
            <span>
              في حال عدم توفير التاجر للمعلومات المطلوبة منه فإن منصة إيزون قدّ تحاول
              الحصول عليها عبر مصادر أخرى&nbsp;
            </span>
            <span style={{ fontSize: "14.0pt" }}>.</span>
          </li>
        </ol>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19, fontWeight: "bold" }}>
            معلومات الإيرادات أو البضائع أو الخدمات الخاصة بالمتاجر
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ol style={{ listStyleType: "decimal" }}>
          <li>
            <span>
              منصة إيزون على اطلاع دائم بالإيرادات الحاصلة في المتجر، وذلك لكون التاجر
              يستخدم بوابات الدفع التي توفّرها منصة إيزون
            </span>
            <span style={{ fontSize: "14.0pt" }}>.</span>
          </li>
          <li>
            <span>
              منصة إيزون على اطلاع بنوع البضائع أو السلع أو الخدمات المعروضة في منصة
              المتجر
            </span>
            <span>.</span>
          </li>
          <li>
            <span>
              منصة إيزون على إطلاع بأداء المتاجر وذلك في حال استدعت الحاجة إلى توجيه
              الدعم أو النصح والإرشاد للتُجّار أو المتاجر لمساعدتهم وتحسين أدائهم بما
              يحقق رواج لما يتم عرضه&nbsp;
            </span>
            <span>.</span>
          </li>
        </ol>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              معلومات مستهلكي المتاجر أو عملاء أو زبائن التجار
            </span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;حيث أن منصة إيزون تسعى إلى الحفاظ على جودة عمل
            المتاجر، وتحسين جودة أعمالهم، فإنها تطلع باستمرار على عدد مستهلكي المتاجر
            وعملائهم وشرائحهم&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              مشاركة المعلومات الخاصة بالمتاجر والتُجّار
            </span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>&nbsp;:</span>
          </strong>
        </p>
        <ol style={{ listStyleType: "decimal" }}>
          <li>
            <span>
              بطبيعة الحال فإن منصة إيزون تسعى للاحتفاظ بهذه المعلومات وذلك بما يحفظ
              خصوصية التاجر، ومنصة إيزون لا تحتفظ بهذه المعلومات إلا بهدف تحسين جودة
              المنصة وجودة عمل المتاجر وتسهيلاً وتيسيراً لأعمال التجار والمتاجر
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span>
              كقاعدة عامة فإن جميع هذه المعلومات لا يطلع عليها إلا بعض القائمين
              المختصين على منصة إيزون وذلك بعد حصولهم على تصريح للاطلاع عليها من قِبل
              إدارة منصة إيزون ، وعادة ما يكون التصريح محدد ومقيّد ويخضع لرقابة
              قانونية وإدارية من قِبل منصة إيزون ولن يتم نشر أو بث هذه المعلومات للغير
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span>
              حيث أن منصة إيزون تسعى للحفاظ على سلامة المتاجر وحقوق التُجّار ، فإنه –
              في حال ملاحظة منصة إيزون لأي نشاط غير نظامي أو غير شرعي يقوم به التاجر –
              فإن منصة إيزون تطبيقاً لمواد العقد المبرم معكم وحيث أنها تسعى بقدر
              الإمكان إلى الحفاظ على سلامة عمل المتاجر فإنها قد تشارك أيٍ من هذه
              المعلومات مع الجهات المختصة لاتخاذ اللازم حيال التاجر أو المتجر المخالف
              ، وذلك لحماية منصة إيزون وباقي التُجّار والمتاجر والمستهلكين من أي
              مسائلة قانونية قدّ تطرأ على المنصة أو على أحد مستخدميها نتيجة لهذا
              النشاط الغير شرعي أو الغير نظامي
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span>
              ان التاجر الذي يبادر بتسجيل متجره لدى منصة إيزون بواسطة شركاء النجاح
              فإنه بذلك يمنح شريك النجاح الذي قام بالتسجيل بواسطته الحق في الاطلاع على
              كافة المعلومات الخاصة بالتاجر ومتجره
            </span>
            <span style={{ fontSize: "14.0pt" }}>.</span>
          </li>
        </ol>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              &nbsp;مدى أمان سرية المعلومات الخاصة بالتُجّار أو المستهلكين أو المتاجر
            </span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; تسعى منصة إيزون إلى الحفاظ على سرية معلومات مستخدمي المنصة ،
            وحيث أن سياسة الخصوصية الخاصة بالتُجّار أو المتاجر لن تخالف أحكام اتفاقية
            الاستخدام أو العقد المبرم أو سياسة الخصوصية وسرية المعلومات ، ولكن نظراً
            لعدم إمكانية ضمان ذلك ــ في بعض الأحوال ـــ بنسبة 100% في ( فضاء الإنترنت
            ) فإن فريق عمل منصة إيزون ، ينوّه على يلي :ـــ&nbsp;
          </span>
        </p>
        <ol style={{ listStyleType: "decimal" }}>
          <li>
            <span>
              تسعى منصة إيزون إلى الحفاظ على جميع المعلومات الخاصة بالمتاجر وألا يطلع
              عليها أي شخص بما يخالف السياسة المعمول بها في منصة إيزون .&nbsp;
            </span>
          </li>
          <li>
            <span>
              تعمل منصة إيزون على حماية معلومات التُجّار والمتاجر بموجب أنظمة حماية
              إلكترونية وتقنية ذات جودة عالية وتُحدّث ذلك بشكل مستمر ودوري&nbsp;
            </span>
            <span style={{ fontSize: 19 }}>.</span>
            <span>&nbsp;</span>
          </li>
          <li>
            <span>
              غير أنه نظراً لأن شبكة الانترنت لا يمكن ضمانها بنسبة &nbsp;100% لما قد
              يطرأ عليها من اختراق أو فيروسات على أنظمة الحماية الإلكترونية وعلى جدران
              الحماية المعمول بها في منصة إيزون فإن منصة إيزون تنصح التجار بالحفاظ على
              معلوماتهم بسرية تامة ، وعدم إفشاء أي معلومات يراها التاجر هامة جداً له ،
              وهذا حرصاً من منصة إيزون على حماية وتوجيه وإرشاد التٌجّار والمتاجر
            </span>
            <span>.</span>
          </li>
        </ol>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              الخدمات الإستراتيجية واللوجستية ( خدمات الطرف الثالث
            </span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>)&nbsp;</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>&nbsp;:</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: "7.5pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; يُقر التاجر بعلمه التام والنافي للجهالة بأنه في حال تقدّم
            لطلب الاشتراك في خدمة مُقدّمة عن طريق طرف ثالث بأنه يمنح تصريح وتخويل وإذن
            كامل وتام إلى منصة إيزون بتزويد مُقدّم الخدمة ببيانات التاجر أو المتجر أو
            المستخدم المشترك لدى مُقدِم الخدمة ، مثل: اسم المستخدم – الهاتف الشخصي –
            البريد الإلكتروني – رقم الإثبات الشخصي أو الإقامة – عنوان المنزل ، وغير
            ذلك من المعلومات التي يحتاجها مقدّم الخدمة (الطرف الثالث) وذلك حتى يتمكّن
            مقدم الخدمة ( الطرف الثالث ) من تقديم الخدمة المطلوبة والتي قام المستخدم
            بالإشتراك فيها&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>.</span>
          <span style={{ fontSize: 19 }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>
              الخـصوصـية لمنصة إيزون للمستخدم &nbsp;:ـ
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp; &nbsp;</span>
          <span style={{ fontSize: 19 }}>
            شركة طموح للتسويق والخدمات الإلكترونية ذ. م. م.، العلامة التجارية&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>&nbsp;"</span>
          <span style={{ fontSize: 19 }}>
            ايزون" ("الشركة" أو "نحن" أو "الخاصة بنا") تحـتـرم خصوصيتك وتلتزم بحمايتها
            من خلال امتثالنا لهذه السياسة
          </span>
          <span style={{ fontSize: 19 }}>.&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>&nbsp; &nbsp;&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            تحدد هذه السياسة وأي مستندات أخرى مشار إليها فيها الأساس الذي نقوم
            بمـوجـبـه بمعالجة البيانات الشخصية &nbsp;ولماذا نقوم بجمع البيانات الشخصية
            وتخزينها واستخدامها ومشاركتها ، وحقوقك فيما يتعلق ببياناتك الشخصية ،
            ويمكنك الاتصال بنا عن طريق إرسال بريد إلكتروني إلى
          </span>
          <span style={{ fontSize: 19 }}>&nbsp;</span>
          <span style={{ fontSize: 19 }}>info@ezone.ly</span>
          <span style={{ fontSize: 19 }}>&nbsp;&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            أو الاتصال بنا من خلال موقعنا الإلكتروني
          </span>
          <span style={{ fontSize: 19 }}>&nbsp;.&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span>
            <br />
            &nbsp;
          </span>
          <span style={{ fontSize: 19 }}>&nbsp; &nbsp; &nbsp;&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            تولي الشركة أهمية قصوى للامتثال لجميع القوانين والتشريعات التي تكفل حماية
            البيانات المعمول بها ، وحرصاً على الشفافية من طرف الشركة فإنها يهمها أن
            تعلم المتعاملين معها بإختلاف صفاتهم أنها &nbsp;هي المتحكم في البيانات
            الشخصية المدرجة على المنصة ، وتوضح هذه السياسة كيفية جمع المعلومات الشخصية
            للعملاء ومـسـتخدمي المـوقع وكيف نستخدمها ونفصح عنها ونحميها ، وتصف أنواع
            المعلومات التي قد نجمعها من زوار
            <span style={{ color: "#222222" }}>&nbsp;موقع الويب</span>
          </span>
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;</span>
          <span style={{ fontSize: 19, color: "#1155CC" }}>www.ezone.ly</span>
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp; (</span>
          <span style={{ fontSize: 19 }}>
            موقعنا الإلكتروني) وممارساتنا في جمع هذه المعلومات واستخدامها وصيانتها
            وحمايتها والكشف عنها وباالتالي فإنه لن &nbsp;يتم استخدام معلوماتك الشخصية
            إلا وفقًا لهذه السياسة ما لم يقتضي القانون المعمول به خلاف ذلك ، ونتخذ
            خطوات لضمان أن المعلومات الشخصية التي يتم جمعها كافية وذات صلة وليست مفرطة
            ومستخدمة لأغراض محدودة ، ولغرض هذا الإشعار فإنه يتم تعريف "المعلومات
            الشخصية" على أنها أي معلومات حول فرد يمكن التعرف عليه ، والتي تتضمن
            معلومات يمكن استخدامها بمفردها أو مع معلومات أخرى لتحديد شخص واحد أو
            الاتصال به أو تحديد موقعه
          </span>
          <span style={{ fontSize: 19 }}>.&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 1 }}>
            <br />
            &nbsp;
          </span>
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;وتنطبق هذه السياسة على المعلومات التي نجمعها أو
            نستخدمها أو نكشف عنها بشأن عملائنا ومستخدمي الموقع الإلكتروني&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>على هذا الموقع</span>
            <span style={{ fontSize: 19 }}>.</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              في البريد الإلكتروني والنصوص والرسائل الإلكترونية الأخرى بينك وبين هذا
              الموقع&nbsp;
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              قد يتضمن موقع الويب روابط إلى مواقع ويب أو مكونات إضافية أو خدمات أو
              شبكات اجتماعية أو تطبيقات تابعة لجهات خارجية وقد يؤدي النقر فوق هذه
              الروابط أو تمكين هذه الاتصالات إلى السماح للطرف الثالث بجمع بيانات عنك
              أو مشاركتها ، وفي حال اتبعت رابطًا إلى موقع ويب تابع لجهة خارجية أو
              استخدمت مكونًا إضافيًا تابعًا لجهة خارجية ، فيرجى ملاحظة أن هذه الأطراف
              الثالثة لديها سياسات الخصوصية الخاصة بها ولا نقبل أي مسؤولية أو التزام
              تجاه هذه السياسات إذ أننا لا نتحكم في مواقع الطرف الثالث هذه ، ونشجعك
              على قراءة سياسة الخصوصية الخاصة بكل موقع تزوره
            </span>
            <span style={{ fontSize: 19 }}>.</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              لا تنطبق هذه السياسة على المعلومات التي تم جمعها من خلال الطـرق
              التالية&nbsp;
            </span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>ـ&nbsp;</span>
          </strong>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              نحن عندما نكون في وضع عدم الاتصال أو من خلال أي وسيلة أخرى ، بما في ذلك
              على أي موقع ويب آخر تديره الشركة أو أي طرف ثالث
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              أي طرف ثالث بما في ذلك الشركات التابعة لنا والشركات المساندة لنا ، من
              خلال أي تطبيق أو محتوى (بما في ذلك الإعلانات) التي قد ترتبط أو يمكن
              الوصول إليها من أو على الموقع
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp; يرجى قراءة هذه السياسة بعناية لفهم سياساتنا
            وممارساتنا الخاصة بجمع معلوماتك ومعالجتها وتخزينها ، وإذا كنت لا توافق على
            سياساتنا وممارساتنا ، فاختيارك هو عدم استخدام موقعنا.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;من خلال الوصول إلى هذا الموقع أو استخدامه ، فإنك تشير
            إلى أنك تفهم وتقبل وتوافق على الممارسات الموضحة في هذه السياسة وقد تتغير
            هذه السياسة من وقت لآخر، ويشير استمرار استخدامك لهذا الموقع بعد إجراء
            التغييرات إلى قبولك وموافقتك على هذه التغييرات ، لذا يرجى مراجعة السياسة
            بشكل دوري للحصول على التحديثات&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>المعلومات التي نجمعها عنك</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>ـ</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نقوم بجمع واستخدام عدة أنواع من المعلومات منك وعنك ، بما في ذلك&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات الشخصية ، التي يمكننا استخدامها بشكل معقول لتحديد هويتك بشكل
              مباشر أو غير مباشر ، مثل اسمك وعنوانك البريدي وتاريخ ميلادك وعنوان بريدك
              الإلكتروني ورقم هاتفك وعنوان بروتوكول الإنترنت
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;(IP)&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              المستخدم لتوصيل جهاز الكمبيوتر الخاص بك بالإنترنت واسم المستخدم أو أي
              معرّف آخر مشابه ، ومعلومات الوسائط الاجتماعية ، وبيانات بطاقة الائتمان /
              الخصم ، وأي معرّف آخر قد نستخدمه للاتصال بك عبر الإنترنت أو دون الاتصال
              بالإنترنت (المعلومات الشخصية )&nbsp;
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات غير الشخصية ، التي لا تكشف بشكل مباشر أو غير مباشر عن هويتك أو
              تتعلق بشكل مباشر بفرد محدد ، مثل المعلومات الديموغرافية أو المعلومات
              الإحصائية أو المُجَمَّعة ولا تحدد البيانات الإحصائية أو المُجَمَّعة
              شخصًا معينًا بشكل مباشر ، ولكن قد نستمد بيانات إحصائية غير شخصية أو
              بيانات مُجَمَّعة من المعلومات الشخصية ، على سبيل المثال &nbsp; &nbsp;
              &nbsp; &nbsp;قد نقوم بتجميع المعلومات الشخصية لحساب النسبة المئوية
              للمستخدمين الذين يصلون إلى ميزة معينة في موقع الويب&nbsp;
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات الفنية ، بما في ذلك معلومات تسجيل الدخول الخاصة بك ، ونوع
              المتصفح وإصداره ، وإعداد المنطقة الزمنية ، وأنواع وإصدارات المكونات
              الإضافية للمتصفح ، ونظام التشغيل والنظام الأساسي ، أو معلومات حول اتصالك
              بالإنترنت ، والمعدات التي تستخدمها للوصول إلى موقعنا الإلكتروني ،
              وتفاصيل الاستخدام&nbsp;
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;.</span>
            <span style={{ fontSize: 19 }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل غير شخصية حول تفاعلاتك على الويب ، بما في ذلك محددات مواقع الويب
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;(URLs)&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              الكاملة ، ومسار النقر إلى ومن خلال ومن موقعنا (بما في ذلك التاريخ
              والوقت) ، والمنتجات التي شاهدتها أو بحثت عنها ، وأوقات استجابة الصفحة ،
              وأخطاء التنزيل ، والطول عدد الزيارات إلى صفحات معينة ، ومعلومات تفاعل
              الصفحة (مثل التمرير والنقرات وتمرير الماوس) ، والطرق المستخدمة للتصفح
              بعيدًا عن الصفحة ، أو أي رقم هاتف مستخدم للاتصال برقم خدمة العملاء لدينا
              .&nbsp;
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>
              كيف نجمع المعلومات عنك
            </span>
          </strong>
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>:&nbsp;</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>ـ</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نستخدم طرقًا مختلفة لجمع معلوماتك ، بما في ذلك من خلال&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              التفاعلات المباشرة معك عندما تزودنا بها ، على سبيل المثال ، عن طريق ملء
              النماذج أو المراسلة معنا عبر الهاتف أو البريد الإلكتروني أو غير ذلك
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              التقنيات أو التفاعلات الآلية ، أثناء تصفحك لموقعنا الإلكتروني فقد تتضمن
              المعلومات التي يتم جمعها تلقائيًا تفاصيل الاستخدام وعناوين
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;IP&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              والمعلومات التي تم جمعها من خلال ملفات تعريف الارتباط وإشارات الويب
              وتقنيات التتبع الأخرى
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>
              المعلومات التي تقدمها لنا
            </span>
          </strong>
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>:</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>ـ</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            المعلومات التي نجمعها منك مباشرة على أو من خلال موقعنا قد تشمل&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات التي تقدمها من خلال ملء النماذج الموجودة على موقعنا الإلكتروني
              &nbsp;ويتضمن ذلك المعلومات المقدمة في وقت التسجيل لاستخدام موقعنا ،
              والاشتراك في خدماتنا ، ونشر المواد ، وطلب المزيد من الخدمات ، قد نطلب
              منك أيضًا معلومات عند دخولك في مسابقة أو عرض ترويجي برعاية ، وعندما تبلغ
              عن مشكلة في موقعنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
            <span style={{ fontSize: 19 }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              سجلات ونُسَخ من مراسلاتك (بما في ذلك عناوين البريد الإلكتروني) ، إذا
              اتصلت بنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              ردودك على الاستبيانات التي قد نطلب منك إكمالها لأغراض البحث
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              نُسَخ من بطاقات إثبات الهوية ، والتي قد تتضمن صورة .&nbsp;
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              بيانات البنك / بطاقة الائتمان .&nbsp;
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              معلومات مستخدم وسائل التواصل الاجتماعي .&nbsp;
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل المعاملات التي تقوم بإجرائها من خلال موقعنا الإلكتروني وتنفيذ
              طلباتك، قد يُطلب منك تقديم معلومات مالية قبل تقديم طلب عبر موقعنا
              الإلكتروني
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              استفسارات البحث الخاصة بك على الموقع&nbsp;
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; يمكنك أيضًا تقديم معلومات ليتم نشرها أو عرضها (يشار إليها
            فيما يلي باسم "منشورة") في المناطق العامة بالموقع الإلكتروني أو نقلها إلى
            مستخدمين آخرين للموقع أو أطراف ثالثة يشار إليها مجتمعة باسم (مساهمات
            المستخدم) . &nbsp;
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;يتم نشر مساهمات المستخدم الخاصة بك وإرسالها إلى
            الآخرين على مسؤوليتك الخاصة، يرجى العلم أنه لا توجد إجراءات أمنية مثالية،
            بالإضافة إلى ذلك ، لا يمكننا التحكم في تصرفات المستخدمين الآخرين للموقع
            الذين قد تختار مشاركة مساهمات المستخدم الخاصة بك معهم، &nbsp;لذلك لا
            يمكننا ولا نضمن أن الأشخاص غير المصرح لهم لن يشاهدوا مساهمات المستخدم
            الخاصة بك&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>كيف نستخدم معلوماتك :ـ</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نستخدم المعلومات التي نجمعها عنك أو التي تقدمها لنا ، بما في ذلك أي
            معلومات شخصية
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتقديم موقعنا الإلكتروني ومحتوياته لك
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتزويدك بالمعلومات أو المنتجات أو الخدمات التي تطلبها منا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتحقيق الأغراض التي من أجلها قدمت المعلومات أو التي تم وصفها عند جمعها ،
              أو أي غرض آخر قمت بتوفيرها من أجله
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتنفيذ التزاماتنا وإنفاذ حقوقنا الناشئة عن أي عقود معك ، بما في ذلك
              الفواتير والتحصيل أو الامتثال للمتطلبات القانونية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لإخطارك بالتغييرات التي تطرأ على موقعنا الإلكتروني أو أي منتجات أو خدمات
              نقدمها أو نقدمها من خلالها
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتحسين موقعنا الإلكتروني أو منتجاتنا أو خدماتنا أو التسويق أو علاقات
              العملاء وتجاربهم
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              للسماح لك بالمشاركة في الميزات التفاعلية أو الوسائط الاجتماعية أو
              الميزات المماثلة على موقعنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لقياس أو فهم فعالية الإعلانات التي نقدمها لك وللآخرين ، ولتقديم
              الإعلانات ذات الصلة لك
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              بأي طريقة أخرى قد نصفــها عند تقديم المعلومات
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>لأي غرض آخر بعـد أخـذ موافقتك</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;قد نستخدم المعلومات التي جمعناها منك لتمكيننا من عرض
            الإعلانات للجمهور المستهدف بالإعلان له ،على الرغم من أننا لا نكشف عن
            معلوماتك الشخصية لهذه الأغراض دون موافقتك ، إذا نقرت على إعلان أو تفاعلت
            معه بطريقة أخرى ، فقد يفترض المعلن أنك تستوفي معاييره المستهدفة
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>الموافقة</span>
          <span style={{ fontSize: 19 }}>:&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            عندما تمنح موافقتك الصريحة على تلقي اتصالات تسويقية ، يجوز لنا استخدام
            بياناتك الشخصية من أجل
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              إرسال رسائل إخبارية واستطلاعات رأي ومعلومات حول جوائزنا وفعالياتنا
              وتخفيضاتنا وعروضنا الترويجية المتعلقة بالمنتجات والخدمات التي يقدمها أحد
              أعضاء الشركة ، والتي قد تهمك .&nbsp;
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تطوير وتحسين وتسليم التسويق والإعلان عن المنتجات والخدمات التي يقدمها
              أحد أعضاء الشركة .&nbsp;
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;نحن نعالج بياناتك الشخصية الحساسة فقط عندما نحصل على موافقتك
            الصريحة على القيام بذلك ويحق لك سحب موافقتك في أي وقت إما عن طريق الاتصال
            بنا باستخدام معلومات الاتصال الواردة فيما بعد&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>الكشف عن المعلومات الخاصة بك:ـ&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;قد نكشف عن معلومات مجمعة حول مستخدمينا ، ومعلومات لا تحدد
            هوية أي فرد ، دون قيود،
          </span>
          <span style={{ fontSize: 19 }}>&nbsp;و</span>
          <span style={{ fontSize: 19 }}>
            قد نكشف عن المعلومات الشخصية التي نجمعها أو نوفرها كما هو موضح في سياسة
            الخصوصية هذه
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>إلى الشركات التابعة أو المساندة لنا</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              وفقًا للقانون المعمول به ، للمشتري أو من يخلفه في حالة الاندماج أو
              التجريد أو إعادة الهيكلة أو إعادة التنظيم أو الحل أو غير ذلك من عمليات
              البيع أو النقل لبعض أو كل أصول الشركة ، سواء كان ذلك كمنشأة مستمرة أو في
              أحوال الإفلاس أو التصفية أو الإجراءات المماثلة ، حيث تكون المعلومات
              الشخصية التي يحتفظ بها عملاؤنا ومستخدمونا من بين الأصول المنقولة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              للمقاولين ومقدمي الخدمات والأطراف الثالثة الأخرى التي نستخدمها لدعم
              أعمالنا (مثل موفري التحليلات ومحركات البحث التي تساعدنا في تحسين موقع
              الويب وتحسينه) والذين يلتزمون تعاقديًا بالحفاظ على سرية المعلومات
              الشخصية ، واستخدامها فقط من أجل الأغراض التي نكشف عنها لهم ، ومعالجة
              المعلومات الشخصية بنفس المعايير المنصوص عليها في هذه السياسة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>لتحقيق الغرض الذي تقدمه من أجله</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لأي غرض آخر نكشف عنه عند تقديم المعلومات
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>قد نكشف أيضًا عن معلوماتك الشخصية</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>ـ</span>
          </strong>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              للامتثال لأي أمر محكمة أو قانون أو إجراء قانوني ، بما في ذلك الرد على أي
              طلب حكومي أو تنظيمي وفقًا للقانون المعمول به
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لفرض أو تطبيق شروط الاستخدام الخاصة بنا
            </span>
            <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;</span>
            <span style={{ fontSize: 19 }}>www.ezone.ly</span>
            <span>&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              أو شروط البيع والاتفاقيات الأخرى ، بما في ذلك أغراض الفواتير والتحصيل
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              إذا كنا نعتقد أن الإفصاح ضروري أو مناسب لحماية حقوق أو ممتلكات أو سلامة
              الشركة أو عملائنا أو غيرهم ويشمل ذلك تبادل المعلومات مع الشركات
              والمؤسسات الأخرى لأغراض الحماية من الاحتيال والحد من مخاطر
              الائتمان&nbsp;
            </span>
            <span style={{ fontSize: 19 }}>.</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>
              اختيارات حول كيفية استخدامنا لمعلوماتك والكشف عنها :ـ
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; نسعى جاهدين لتزويدك بالخيارات المتعلقة بالمعلومات الشخصية
            التي تزودنا بها لـهذا أنشأنا آليات لتزويدك بالسيطرة التالية على
            معلوماتك&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تقنيات التتبع والإعلان حيث يمكنك ضبط متصفحك على رفض كل أو بعض ملفات
              تعريف الارتباط للمتصفح ، أو لتنبيهك عند إرسال ملفات تعريف الارتباط
              لمعرفة كيفية إدارة إعدادات ملفات تعريف الارتباط الخاصة ببرنامج "فلاش
            </span>
            <span style={{ fontSize: 19 }}>" Flash&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              ، تَـفـضَّل بزيارة صفحة إعدادات مشغل"فلاش
            </span>
            <span style={{ fontSize: 19 }}>" Flash&nbsp;</span>
            <span style={{ fontSize: 19 }}>على موقع "أدوبي</span>
            <span style={{ fontSize: 19 }}>" Adobe&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              على الويب ، &nbsp;وإذا قمت بتعطيل ملفات تعريف الارتباط أو رفضتها ، فيرجى
              ملاحظة أن بعض أجزاء هذا الموقع قد لا يمكن الوصول إليها أو قد لا تعمل
              بشكل صحيح
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              الإعلانات المستهدفة فإذا كنت لا تريد منا استخدام المعلومات التي نجمعها
              أو التي تقدمها لنا لتقديم إعلانات وفقًا لتفضيلات الجمهور المستهدف
              للمعلنين ، فيمكنك إلغاء الاشتراك عن طريق الاتصال بنا مباشرة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              نحن لا نتحكم في جمع الأطراف الثالثة لمعلوماتك أو استخدامها لخدمة
              الإعلانات القائمة على الاهتمامات ومع ذلك ، قد توفر لك هذه الجهات
              الخارجية طرقًا لاختيار عدم جمع معلوماتك أو استخدامها بهذه الطريقة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              حيثما يقتضي القانون ، يجوز لنا أيضًا معالجة بياناتك الشخصية إذا كان ذلك
              مطلوبًا بموجب القانون ، بما في ذلك الاستجابة لطلبات الحكومة أو سلطات
              إنفاذ القانون ، أو لمنع الجريمة أو الاحتيال
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>أمن البيانات :ـ</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; أمان معلوماتك الشخصية مهم جدًا بالنسبة لنا ولـذا نحن نستخدم
            إجراءات مادية وإلكترونية وإدارية مصممة لتأمين معلوماتك الشخصية من الضياع
            العرضي ومن الوصول غير المصرح به والاستخدام والتعديل والإفشاء
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; تعتمد سلامة وأمن معلوماتك عليك أيضًا.فعندما نقدم لك (أو
            حيثما اخترت) كلمة مرور للوصول إلى أجزاء معينة من موقعنا ، فأنت مسؤول عن
            الحفاظ على سرية كلمة المرور هذه ونطلب منك عدم مشاركة كلمة المرور الخاصة بك
            مع أي شخص&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>.</span>
          <span style={{ fontSize: 19 }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; للأسـف فإن نقل المعلومات عبر الإنترنت ليس آمنًا تمامًا على
            الرغم من أننا نبذل قصارى جهدنا لحماية معلوماتك الشخصية ، لا يمكننا ضمان
            أمن معلوماتك الشخصية المنقولة إلى موقعنا ولذلك فإن أي نقل للمعلومات
            الشخصية يكون على مسؤوليتك الخاصة ، ونحن لسنا مسؤولين عن التحايُــل على أي
            إعدادات خصوصية أو تدابير أمنية واردة على الموقع
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>
              الاحتفاظ بالبيانات :ـ
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; باستثناء ما هو مسموح به أو مطلوب من قبل القانون أو اللوائح
            المعمول بها ، سنحتفظ فقط بمعلوماتك الشخصية طالما كان ذلك ضروريًا لتحقيق
            الأغراض التي جمعناها من أجلها ، بما في ذلك لأغراض تلبية أي متطلبات قانونية
            أو محاسبية أو إعداد تقارير ، وفي بعض الظروف &nbsp;قد نجعل معلوماتك الشخصية
            مجهولة بحيث لا يمكن ربطها بك ونحتفظ بالحق في استخدام مثل هذه البيانات
            المجهولة وغير المُحَـدِدة للهوية لأي غرض تجاري مشروع دون إشعار آخر لك أو
            بموافقتك&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>.</span>
          <span style={{ fontSize: 19 }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              أي شخص دون سن ثمانية عشر عاماً ( 18 عامًا ) :ــ&nbsp;
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>&nbsp; &nbsp;&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            موقعنا الإلكتروني غير مخصص لأي شخص دون سن ثمانية عشر عاماً (18 عامًا ) إذ
            أنه لا يجوز لأي شخص أقل من هذا السن تقديم أي معلومات شخصية إلى أو على
            الموقع، ونحن لا نجمع معلومات شخصية عن قصد من أي شخص أقل من 18 عامًا . فإذا
            كان عمرك أقل من 18 عامًا ، فلا تستخدم أو تقدم أي معلومات على هذا الموقع أو
            على أو من خلال أي من ميزاته / ولا تقم بالتسجيل على موقع الويب ، ولا تـقُـم
            بإجراء أي عمليات شراء من خلال الموقع ، ولا تستخدام أي من ميزات التعليق
            التفاعلي أو العام لهذا الموقع ، أو تقدم أي معلومات عنك إلينا ، بما في ذلك
            اسمك وعنوانك ورقم هاتفك وعنوان بريدك الإلكتروني أو أي اسم مستخدم قد
            تستخدمه، وإذا علمنا أننا جمعنا أو تلقينا معلومات شخصية من أي شخص أقل من 18
            عامًا دون التحقق من موافقة الوالدين ، فسنحذف هذه المعلومات، وإذا كنت تعتقد
            أنه قد يكون لدينا أي معلومات من أو عن أي شخص أقل من 18 عامًا، فيرجى
            الاتصال بنا مباشرة على
          </span>
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;</span>
          <span style={{ fontSize: 19, color: "#1155CC" }}>info@ezone.ly</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              الوصول إلى معلوماتك الشخصية وتصحيحها :ــ&nbsp;
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;من المهم أن تكون المعلومات الشخصية التي نحتفظ بها عنك دقيقة
            وحديثة ويرجى تحديث بياناتك لـدينا إذا تغيرت معلوماتك الشخصية ، وبموجب
            القانون يحق لك طلب الوصول إلى المعلومات الشخصية التي نحتفظ بها عنك
            وتصحيحها
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; قد نطلب منك معلومات محددة لمساعدتنا في تأكيد هويتك وحقك في
            الوصول ، وتزويدك بالمعلومات الشخصية التي نحتفظ بها عنك أو إجراء التغييرات
            المطلوبة، قد يسمح القانون المعمول به أو يطلب منا رفض تزويدك بإمكانية
            الوصول إلى بعض أو كل المعلومات الشخصية التي نحتفظ بها عنك ، أو ربما قمنا
            بتدمير معلوماتك الشخصية أو محوها أو جعلها مجهولة الهوية وفقًا لالتزامات
            الاحتفاظ بالسجلات والممارسات ، وإذا لم نتمكن من تزويدك بإمكانية الوصول إلى
            معلوماتك الشخصية ، فسنبلغك بأسباب ذلك مع مراعاة أي قيود قانونية أو تنظيمية
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;سنوفر الوصول إلى معلوماتك الشخصية ، مع مراعاة الاستثناءات
            المنصوص عليها في التشريعات المعمول بها ومن أمثلة هذه الاستثناءات ما
            يلي&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات المحمية بامتياز المحامي بصفته - العميل
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات التي هي جزء من عملية تسوية المنازعات الرسمية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات التي تتعلق بفرد آخر من شأنها أن تكشف عن معلوماته الشخصية أو
              المعلومات التجارية السرية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات التي يكون توفيرها باهظ التكلفة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>سحب موافقتك</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>:</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>&nbsp; &nbsp;</span>
          <span style={{ fontSize: 19 }}>
            في حالة موافقتك على جمع معلوماتك الشخصية واستخدامها ونقلها ، فقد يكون لك
            الحق القانوني في سحب موافقتك في ظل ظروف معينة ، لسحب موافقتك &nbsp;إن أمكن
            &nbsp; اتصل بنا مباشرة على
          </span>
          <span style={{ fontSize: 19 }}>&nbsp;</span>
          <span style={{ fontSize: 19, color: "#1155CC" }}>info@ezone.ly</span>
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            يرجى ملاحظة أنه في حالة سحب موافقتك ، فقد لا نتمكن من تزويدك بمنتج أو خدمة
            معينة وسنشرح لك التأثير في ذلك الوقت لمساعدتك في قرارك
          </span>
          <span style={{ fontSize: 19 }}>&nbsp;.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>حقوقك&nbsp;</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:</span>
          </strong>
        </p>
        <ol style={{ listStyleType: "decimal", marginLeft: "1.25px" }}>
          <li>
            <span>الحق في الوصول</span>
            <span>:&nbsp;</span>
            <span style={{ fontSize: "14.0pt" }}>
              &nbsp;يحق لك طلب نسخة من البيانات الشخصية التي نحتفظ بها عنك عن طريق
              الاتصال بنا على البريد الإلكتروني المقدم ، يرجى تضمين معلومات طلبك التي
              ستمكننا من التحقق من هويتك وسنرد خلال 30 يومًا من الطلب، يرجى ملاحظة أن
              هناك استثناءات لهذا الحق فقد لا نتمكن من إتاحة جميع المعلومات لك إذا كان
              على سبيل المثال إتاحة المعلومات لك من شأنه أن يكشف عن بيانات شخصية عن
              شخص آخر، أو إذا تم منعنا قانونًا من الكشف عن هذه المعلومات أو إذا كان
              طلبك بلا أساس أو مبالغ فيه&nbsp;
            </span>
            <span>.</span>
          </li>
          <li>
            <span>الحق في التصحيح</span>
            <span>:&nbsp;</span>
            <span style={{ fontSize: "14.0pt" }}>
              &nbsp;نهدف إلى الحفاظ على دقة بياناتك الشخصية واكتمالها ونشجعك على
              الاتصال بنا باستخدام تفاصيل الاتصال الواردة أدناه لإعلامنا إذا كان أي من
              بياناتك الشخصية غير دقيق أو يتغير ، حتى نتمكن من تحديث بياناتك الشخصية
              باستمرار
            </span>
            <span>.</span>
          </li>
          <li>
            <span>الحق في الحذف</span>
            <span>:&nbsp;</span>
            <span style={{ fontSize: "14.0pt" }}>
              &nbsp;يحق لك طلب حذف بياناتك الشخصية ومن ذلك على سبيل المثال، إذا لم تعد
              البيانات الشخصية ضرورية للأغراض التي تم جمعها من أجلها ، حيث تسحب
              موافقتك على المعالجة، حيث لا يوجد تجاوز مصلحة مشروعة لنا لمواصلة معالجة
              بياناتك الشخصية، أو تمت معالجة بياناتك الشخصية بشكل غير قانوني ، وإذا
              كنت ترغب في طلب مسح بياناتك الشخصية فيرجى الاتصال بنا باستخدام تفاصيل
              الاتصال الواردة أدناه
            </span>
            <span>.</span>
            <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
          </li>
          <li>
            <span>الحق في الاعتراض</span>
            <span>:&nbsp;</span>
            <span style={{ fontSize: "14.0pt" }}>
              &nbsp;في ظروف معينة &nbsp; يحق لك الاعتراض على معالجة بياناتك الشخصية
              حيث، على سبيل المثال، تتم معالجة بياناتك الشخصية على أساس المصالح
              المشروعة ولا توجد مصلحة مشروعة غالبة بالنسبة لنا للاستمرار معالجة
              بياناتك الشخصية ، أو في حالة معالجة بياناتك لأغراض التسويق المباشر ،
              وإذا كنت ترغب في الاعتراض على الضغط على بياناتك الشخصية ، فيرجى الاتصال
              بنا باستخدام تفاصيل الاتصال الواردة أدناه
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span>الحق في تقييد المعالجة</span>
            <span style={{ fontSize: "14.0pt" }}>:&nbsp;</span>
            <span style={{ fontSize: "14.0pt" }}>
              &nbsp;في ظروف معينة ، يحق لك طلب تقييد المعالجة الإضافية لبياناتك
              الشخصية وينشأ هذا الحق على سبيل المثال ، عندما تكون قد طعنت في دقة
              البيانات الشخصية التي نحتفظ بها عنك ونحن نتحقق من المعلومات ، واعترضت
              على المعالجة بناءً على المصالح المشروعة وننظر فيما إذا كانت هناك أي
              اهتمامات مشروعة سائدة ، المعالجة غير قانونية وأنت تختار أن المعالجة
              مقيدة بدلاً من حذفها فيرجى الاتصال بنا باستخدام تفاصيل الاتصال الواردة
              أدناه
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span>الحق في إمكانية نقل البيانات</span>
            <span style={{ fontSize: "14.0pt" }}>:&nbsp;</span>
            <span style={{ fontSize: "14.0pt" }}>
              &nbsp;في ظروف معينة ، يحق لك طلب توفير بعض بياناتك الشخصية لك ، أو إلى
              وحدة تحكم أخرى في البيانات ، بتنسيق شائع الاستخدام ويمكن قراءته آليًا
              وينشأ هذا الحق عندما تقدم بياناتك الشخصية إلينا ، وتعتمد المعالجة على
              الموافقة أو أداء العقد ، وتتم المعالجة بوسائل آلية ، وإذا كنت ترغب في
              طلب نقل بياناتك الشخصية إليك ، فيرجى الاتصال بنا باستخدام تفاصيل الاتصال
              الواردة أدناه
            </span>
            <span>.</span>
          </li>
        </ol>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; يرجى ملاحظة أن هناك استثناءات لهذه الحقوق ، على سبيل المثال
            ، إذا كنا ملزمين قانونيًا بالاستمرار في معالجة بياناتك الشخصية. إذا لم
            نتمكن من الامتثال لطلبك بسبب استثناء ، فسنشرح لك ذلك في ردنا
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>الضمانات</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;هناك العديد من الضمانات التي تنفذها الشركة لضمان حماية
            البيانات وفي حالة وجود خرقٍ ما ، سنبلغك في أقرب وقت ممكن
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              التغييرات في سياسة الخصوصية الخاصة بنا :ـ
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;تتمثل سياستنا في نشر أي تغييرات نجريها على سياسة الخصوصية
            الخاصة بنا على هذه الصفحة مع إشعار بأنه تم تحديث سياسة الخصوصية على الصفحة
            الرئيسية لموقع الويب ، إذا أجرينا تغييرات جوهرية على كيفية تعاملنا مع
            المعلومات الشخصية لمستخدمينا فسنخطرك عبر البريد الإلكتروني على عنوان
            البريد الإلكتروني الأساسي المحدد في حسابك أو من خلال إشعار على الصفحة
            الرئيسية لموقع الويب وسنقوم بتضمين تاريخ آخر مراجعة لسياسة الخصوصية في
            أعلى الصفحة ، وأنت مسؤول عن ضمان حصولنا على عنوان بريد إلكتروني محدث ونشط
            وقابل للتسليم لك ، وزيارة موقعنا بشكل دوري وسياسة الخصوصية هذه للتحقق من
            أي تغييرات
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>
              معلومات الاتصال وتحدي الامتثال
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19, color: "#222222" }}>
            نرحب بأسئلتك وتعليقاتك وطلباتك فيما يتعلق بسياسة الخصوصية هذه وممارسات
            الخصوصية لدينا ، ويرجى الاتصال بنا على&nbsp;
          </span>
          <span style={{ fontSize: 19, color: "#222222" }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              color: "#222222",
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19, color: "windowtext" }}>info@ezone.ly</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              color: "#222222",
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              شركة طموح للتسويق والخدمات الإلكترونية ذ.م.م
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              مبنى ريم البوادي – الدور الرابع- , المدينة السياحية / ابونواس - طرابلس –
              ليبيا
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>موبايل : 8829999 91 218+</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>موبايل : 8829999 92 218+</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19, color: "#222222" }}>
            &nbsp; لدينا إجراءات معمول بها لتلقي الشكاوى أو الاستفسارات والرد عليها
            حول تعاملنا مع المعلومات الشخصية ، وامتثالنا لهذه السياسة ، وقوانين
            الخصوصية المعمول بها ، ولمناقشة امتثالنا لهذه السياسة ، يرجى الاتصال بنا
            على&nbsp;
          </span>
          <a href="mailto:info@ezone.ly">
            <span style={{ fontSize: 19 }}>info@ezone.ly</span>
          </a>
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;</span>
          <span style={{ fontSize: 19, color: "#222222" }}>
            &nbsp;باستخدام معلومات الاتصال المذكورة أعلاه
          </span>
          <span style={{ fontSize: 19, color: "#222222" }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>
              سياسة خصوصية تطبيقات الجوال
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19, color: "#222222" }}>المقدمة</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19, color: "#222222" }}>
            شركة طموح للتسويق والخدمات الإلكترونية
          </span>
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;</span>
          <span style={{ fontSize: 19, color: "#222222" }}>
            ذ.م.م.، العلامة التجارية&nbsp;
          </span>
          <a href="http://www.ezone.ly">
            <span style={{ fontSize: 19 }}>www.ezone.ly</span>
          </a>
          <span style={{ fontSize: 19, color: "#222222" }}>&nbsp;"</span>
          <span style={{ fontSize: 19, color: "#222222" }}>
            &nbsp;الشركة" أو "نحن") تحترم خصوصيتك وتلتزم بحمايتها من خلال الامتثال
            لهذه السياسة
          </span>
          <span style={{ fontSize: 19, color: "#222222" }}>.</span>
          <span style={{ fontSize: 19 }}>تصف هذه السياسة</span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        ></p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              أنواع المعلومات التي قد نجمعها أو قد يوفرها مستخدمو التطبيق ("أنت") عند
              شراء التطبيق أو تنزيله أو تثبيته أو التسجيل فيه أو الوصول إليه أو
              استخدامه "التطبيق
            </span>
            <span style={{ fontSize: 19 }}>"</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              ممارساتنا في جمع هذه المعلومات واستخدامها وصيانتها وحمايتها والكشف عنها
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; سنستخدم معلوماتك الشخصية فقط وفقًا لهذه السياسة ما لم يقتضي
            القانون المعمول به خلاف ذلك. نتخذ خطوات لضمان أن المعلومات الشخصية التي
            نجمعها عنك كافية وليست مُفرِطة ومُستخدمة لأغراض محدودة
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;تنطبق هذه السياسة فقط على المعلومات التي نجمعها في هذا
            التطبيق و / ، في البريد الإلكتروني ، والرسائل النصية ، والاتصالات
            الإلكترونية الأخرى المرسلة من خلال أو فيما يتعلق بهذا التطبيق (أي موقع
            الويب)
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; لا تنطبق هذه السياسة على المعلومات التي تقدمها أنت إلى أو
            يتم جمعها من قبل أي طرف ثالث (انظر جمع معلومات الطرف الثالث)
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;قد يكون لمواقعنا الإلكترونية وتطبيقاتنا وهذه الجهات
            الخارجية سياسات الخصوصية الخاصة بها ، والتي نشجعك على قراءتها قبل تقديم
            معلومات عنها أو من خلالها
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;يرجى قراءة هذه السياسة بعناية لفهم سياساتنا وممارساتنا
            الخاصة بجمع معلوماتك ومعالجتها وتخزينها، إذا كنت لا توافق على سياساتنا
            وممارساتنا ، فلا تقم بتنزيل هذا التطبيق أو التسجيل فيه أو استخدامه عن طريق
            تنزيل هذا التطبيق أو التسجيل فيه أو استخدامه ، فإنك تشير إلى أنك تفهم
            الممارسات الموضحة في سياسة الخصوصية هذه وتقبلها وتوافق عليها قد تتغير هذه
            السياسة من وقت لآخر (انظر التغييرات في سياسة الخصوصية الخاصة بنا). يشير
            استمرار استخدامك لهذا التطبيق بعد إجراء التغييرات إلى قبولك وموافقتك على
            هذه التغييرات ، لذا يرجى مراجعة السياسة بشكل دوري للحصول على التحديثات ،
            سنخطرك مسبقًا بأي تغييرات جوهرية في هذه السياسة ونحصل على موافقتك على أي
            طرق جديدة نجمعها ونستخدمها ونفصح عنها
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              المعلومات التي نجمعها عنك وكيف نجمعها
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>نجمع معلومات عنك من خلال</span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              التفاعلات المباشرة معك عندما تزودنا بها ، على سبيل المثال ، عن طريق ملء
              النماذج أو المراسلة معنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              التقنيات أو التفاعلات الآلية ، عند استخدام التطبيق ، على سبيل المثال ،
              تفاصيل الاستخدام وعناوين
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;IP&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              والمعلومات التي تم جمعها من خلال ملفات تعريف الارتباط وإشارات الويب
              وتقنيات التتبع الأخرى
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            تشمل أنواع المعلومات التي نجمعها ما يلي&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نقوم بجمع واستخدام عدة أنواع من المعلومات منك وعنك ، بما في ذلك
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات الشخصية التي يمكننا استخدامها بشكل معقول لتحديد هويتك بشكل
              مباشر أو غير مباشر مثل اسمك وعنوانك البريدي وتاريخ ميلادك وعنوان بريدك
              الإلكتروني ورقم هاتفك وعنوان بروتوكول الإنترنت
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;(IP)&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              المستخدم لتوصيل جهاز الكمبيوتر الخاص بك بالإنترنت واسم المستخدم أو أي
              مُعِّرف مُشَابِه آخر ومعلومات الوسائط الاجتماعية وبيانات بطاقة الائتمان
              / الخصم وأي مُعِّرف آخر قد نستخدمه للاتصال بك عبر الإنترنت أو دون
              الاتصال بالإنترنت ("المعلومات الشخصية
            </span>
            <span style={{ fontSize: 19 }}>"</span>
            <span style={{ fontSize: 19 }}>)</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات غير الشخصية التي لا تكشف بشكل مباشر أو غير مباشر عن هويتك أو
              تتعلق بشكل مباشر بفرد محدد مثل المعلومات الديموغرافية أو المعلومات
              الإحصائية أو المجمعة. لا تحدد البيانات الإحصائية أو المجمعة شخصًا معينًا
              بشكل مباشر، ولكن قد نستمد بيانات إحصائية غير شخصية أو بيانات مجمعة من
              المعلومات الشخصية. على سبيل المثال قد نقوم بتجميع المعلومات الشخصية
              لحساب النسبة المئوية للمستخدمين الذين يصلون إلى ميزة تطبيق معينة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات الفنية، بما في ذلك معلومات تسجيل الدخول الخاصة بك ونوع المتصفح
              والإصدار وإعداد المنطقة الزمنية وأنواع وإصدارات المكونات الإضافية
              للمتصفح ونظام التشغيل والنظام الأساسي، أو معلومات حول اتصالك بالإنترنت
              والأجهزة التي تستخدمها للوصول إلى تطبيقنا وتفاصيل الاستخدام
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل غير شخصية حول تفاعلات التطبيق، بما في ذلك محددات مواقع الويب
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;(URLS)&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              الكاملة ومسار النقر إلى التطبيق ومن خلاله (بما في ذلك التاريخ والوقت)
              والمنتجات التي شاهدتها أو بحثت عنها وأوقات استجابة الصفحة وأخطاء التنزيل
              والطول الزيارات إلى صفحات معينة ومعلومات تفاعل الصفحة (مثل التمرير
              والنقرات وتمرير الماوس) والطرق المستخدمة للتصفح بعيدًا عن الصفحة، أو أي
              رقم هاتف مستخدم للاتصال برقم خدمة العملاء لدينا .&nbsp;
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات الفنية، بما في ذلك معلومات تسجيل الدخول الخاصة بك ونوع الجهاز
              وإعداد المنطقة الزمنية وتفاصيل الاستخدام
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>المعلومات التي تقدمها لنا</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            عند تنزيل هذا التطبيق أو التسجيل به أو استخدامه قد نطلب منك تقديم التالي
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات عن طريق ملء النماذج في التطبيق، يتضمن ذلك المعلومات التي
              تقدمها عند التسجيل لاستخدام التطبيق والاشتراك في خدمتنا ونشر المواد أو
              طلب خدمات أخرى. قد نطلب منك أيضًا معلومات عند دخولك في مسابقة أو عرض
              ترويجي برعاية وعندما تبلغ عن مشكلة في التطبيق
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              سجلات ونسخ من مراسلاتك، بما في ذلك عناوين البريد الإلكتروني وأرقام
              الهواتف، إذا اتصلت بنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              نسخ من بطاقات الهوية، والتي قد تحتوي على صورة
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>بيانات البنك / بطاقة الائتمان</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              معلومات مستخدم وسائل التواصل الاجتماعي
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل المعاملات التي تجريها من خلال تطبيقنا أو موقعنا الإلكتروني
              وتفاصيل تنفيذ طلباتك. قد يُطلب منك تقديم معلومات مالية قبل تقديم طلب عبر
              تطبيقنا أو موقعنا الإلكتروني
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              ردودك على الاستبيانات التي قد نطلب منك إكمالها للبحث أو لأغراض أخرى
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل المعاملات التي تجريها من خلال التطبيق وتنفيذ الطلبات. قد نطلب منك
              تقديم معلومات مالية قبل تقديم طلب عبر التطبيق
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              استفسارات البحث الخاصة بك على التطبيق
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;يمكنك أيضًا تقديم معلومات للنشر أو العرض ("منشور") في
            المناطق العامة من التطبيق التي تصل إليها من خلال التطبيق (يُشار إليها
            إجمالاً باسم "مساهمات المستخدم"). أنت تنشر وتنقل مساهمات المستخدمين
            للآخرين على مسؤوليتك الخاصة. على الرغم من أنه يمكنك تعيين إعدادات خصوصية
            معينة لهذه المعلومات عن طريق تسجيل الدخول إلى ملف تعريف حسابك .&nbsp;
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              جمع المعلومات التلقائي وتتبع التقنيات
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            عند تنزيل التطبيق والوصول إليه واستخدامه ، فقد يجمع تلقائيًا ما يلي
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل الاستخدام. عند الوصول إلى التطبيق واستخدامه، قد نجمع تلقائيًا
              تفاصيل معينة عن وصولك إلى التطبيق واستخدامه، بما في ذلك بيانات حركة
              المرور وبيانات الموقع والسجلات وبيانات الاتصال الأخرى والموارد التي تصل
              إليها وتستخدمها على التطبيق أو من خلاله
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              معلومات الجهاز. قد نجمع معلومات حول جهازك المحمول واتصالك بالإنترنت، بما
              في ذلك المُعِّرف الفريد للجهاز وعنوان
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;IP&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              ونظام التشغيل ونوع المتصفح ومعلومات شبكة الهاتف المحمول ورقم هاتف الجهاز
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات والملفات المُخزَّنة. قد يصل التطبيق أيضًا إلى البيانات الوصفية
              والمعلومات الأخرى المرتبطة بالملفات الأخرى المخزنة على جهازك. قد يشمل
              ذلك على سبيل المثال، الصور الفوتوغرافية ومقاطع الصوت والفيديو وجهات
              الاتصال الشخصية ومعلومات دفتر العناوين
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              معلومات الموقع. يجمع هذا التطبيق / لا يجمع معلومات في الوقت الفعلي حول
              موقع جهازك. قد تتضمن التقنيات التي نستخدمها لجمع البيانات تلقائيًا ما
              يلي
            </span>
            <span style={{ fontSize: 19 }}>:</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              ملفات تعريف الارتباط (أو ملفات تعريف الارتباط للجوال). ملف تعريف
              الارتباط هو ملف صغير يتم وضعه على هاتفك الذكي. قد يكون من الممكن رفض
              قبول ملفات تعريف الارتباط الخاصة بالهاتف المحمول عن طريق تنشيط الإعداد
              المناسب على هاتفك الذكي. ومع ذلك، إذا حددت هذا الإعداد، فقد لا تتمكن من
              الوصول إلى أجزاء معينة من تطبيقنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              إشارات الويب. قد تحتوي صفحات التطبيق ورسائل البريد الإلكتروني الخاصة بنا
              على ملفات إلكترونية صغيرة تُعرف باسم إشارات الويب (يشار إليها أيضًا باسم
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;gifs&nbsp;</span>
            <span style={{ fontSize: 19 }}>الواضحة وعلامات البكسل وصور</span>
            <span style={{ fontSize: 19 }}>&nbsp;gif&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              أحادية البكسل) التي تسمح للشركة، على سبيل المثال، بإحصاء المستخدمين
              الذين زاروا تلك الصفحات أو فتح بريدًا إلكترونيًا ولإحصاءات التطبيقات
              الأخرى ذات الصلة (على سبيل المثال، تسجيل شعبية محتوى تطبيق معين والتحقق
              من سلامة النظام والخادم) .&nbsp;
            </span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; قد نستخدم هذه التقنيات أيضًا لجمع معلومات حول أنشطتك بمرور
            الوقت وعبر مواقع الويب أو التطبيقات أو الخدمات الأخرى عبر الإنترنت التابعة
            لجهات خارجية (التتبع السلوكي). لمعرفة المزيد أو إلغاء الاشتراك في
            الإعلانات المخصصة، يرجى زيارة أداة إلغاء الاشتراك في تحالف الإعلان الرقمي
            في ليبيا للحصول على معلومات حول كيفية إلغاء الاشتراك في التتبع السلوكي في
            هذا التطبيق أو من خلاله وكيفية استجابتنا لإشارات المتصفح والآليات الأخرى
            التي تساعد يمارس المستخدمون خياراتهم بشأن التتبع السلوكي
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; قد نحتفظ بالمعلومات أو نربطها بالمعلومات الشخصية التي نجمعها
            بطرق أخرى، أو التي تقدمها لنا، أو نتلقاها من أطراف ثالثة. تساعدنا معلومات
            الاستخدام هذه على تحسين تطبيقنا وتقديم خدمة أكثر تخصيصًا، بما في ذلك
            مساعدتنا على
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>تقدير حجم جمهورنا وأنماط الاستخدام</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تخزين المعلومات حول تفضيلاتك وتخصيص تطبيقنا وفقًا لاهتماماتك الفردية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>تسريع عمليات البحث الخاصة بك</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>التعرف عليك عند استخدام التطبيق</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; إذا كنت لا تريد منا جمع هذه المعلومات ، فلا تقم بتنزيل
            التطبيق أو قـم بحذفه من جهازك. وعليك أن تلاحظ أن إلغاء الاشتراك في مجموعة
            معلومات الموقع الخاصة بالتطبيق سيؤدي إلى تعطيل الميزات المستندة إلى الموقع
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>كيف نستخدم معلوماتك</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نستخدم المعلومات التي نجمعها عنك أو التي تقدمها لنا ، بما في ذلك أي
            معلومات شخصية
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>لتزويدك بالتطبيق ومحتوياته</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتزويدك بالمعلومات أو المنتجات أو الخدمات التي تطلبها منا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتحقيق الأغراض التي قدمتها من أجلها أو التي تم وصفها عند جمعها ، أو أي
              غرض آخر تقدمه من أجله
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتقديم إخطارات حول حسابك / اشتراكك، بما في ذلك إشعارات انتهاء الصلاحية
              والتجديد
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتنفيذ التزاماتنا وإنفاذ حقوقنا في أي عقود معك، بما في ذلك الفواتير
              والتحصيل أو الامتثال للمتطلبات القانونية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لإخطارك عند توفر تحديثات التطبيق، وبالتغييرات التي تطرأ على أي منتجات أو
              خدمات نقدمها أو نقدمها من خلالها
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتحسين تطبيقنا أو منتجاتنا أو خدماتنا أو علاقات العملاء وتجاربهم
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>لأغراض التسويق</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              للسماح لك بالمشاركة في الميزات التفاعلية أو الوسائط الاجتماعية أو ميزات
              أخرى على تطبيقنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لقياس أو فهم فعالية الإعلانات التي نقدمها لك وللآخرين ، ولتقديم
              الإعلانات ذات الصلة لك
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>لأي غرض آخر بموافقتك</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            تساعدنا معلومات الاستخدام التي نجمعها على تحسين تطبيقنا وتقديم تجربة أفضل
            وأكثر تخصيصًا ، بما في ذلك عن طريق تمكيننا من
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>تقدير حجم جمهورنا وأنماط الاستخدام</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              قم بتخزين معلومات حول تفضيلاتك، مما يسمح لنا بتخصيص تطبيقنا وفقًا
              لاهتماماتك الفردية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>تسريع عمليات البحث الخاصة بك</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>التعرف عليك عند استخدام التطبيق</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>&nbsp; &nbsp;&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            قد نستخدم معلوماتك أيضًا للاتصال بك بشأن سلعنا وخدماتنا الخاصة والأطراف
            الثالثة التي قد تهمك وفقًا لما يسمح به القانون. إذا كنت لا تريد منا
            استخدام معلوماتك بهذه الطريقة، فيرجى الاتصال بنا مباشرة
            <span style={{ color: "black" }}>&nbsp;على</span>
          </span>
          <span style={{ fontSize: 19, color: "black" }}>&nbsp;</span>
          <span>
            <a href="mailto:info@nezone.ly">
              <span style={{ fontSize: 19 }}>info@ezone.ly</span>
            </a>
          </span>
          <span style={{ fontSize: 19, color: "black" }}>&nbsp;</span>
          <span style={{ fontSize: 19 }}>
            حدد المربع ذي الصلة الموجود في النموذج الذي نجمع فيه نموذج طلب البيانات /
            نموذج التسجيل، واضبط تفضيلات المستخدم في ملف تعريف الحساب ، استخدم آلية
            إلغاء الاشتراك في الجزء السفلي من رسائل البريد الإلكتروني الخاصة بنا وأرسل
            لنا بريدًا إلكترونيًا على
          </span>
          <span style={{ fontSize: 19 }}>&nbsp; info@ezone.ly</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            لمزيد من المعلومات، راجع خيارات حول كيفية استخدامنا لمعلوماتك والكشف عنها
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; قد نستخدم المعلومات التي جمعناها منك لعرض الإعلانات على
            الجماهير المستهدفة من معلنينا. على الرغم من أننا لا نكشف عن معلوماتك
            الشخصية لهذه الأغراض دون موافقتك. إذا نقرت على إعلان أو تفاعلت معه بطريقة
            أخرى، فقد يفترض المعلن أنك تستوفي المعايير المستهدفة
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>المعلومات التي نجمعها عنك</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نقوم بجمع واستخدام عدة أنواع من المعلومات منك وعنك، بما في ذلك
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات الشخصية، التي يمكننا استخدامها بشكل معقول لتحديد هويتك بشكل
              مباشر أو غير مباشر ، مثل اسمك وعنوانك البريدي وتاريخ ميلادك وعنوان بريدك
              الإلكتروني ورقم هاتفك وعنوان بروتوكول الإنترنت
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;(IP)&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              المستخدم لتوصيل جهاز الكمبيوتر الخاص بك بالإنترنت واسم المستخدم أو أي
              معرّف آخر مشابه ، ومعلومات الوسائط الاجتماعية ، وبيانات بطاقة الائتمان /
              الخصم ، وأي معرّف آخر قد نستخدمه للاتصال بك عبر الإنترنت أو دون الاتصال
              بالإنترنت ("المعلومات الشخصية
            </span>
            <span style={{ fontSize: 19 }}>"</span>
            <span style={{ fontSize: 19 }}>)&nbsp;</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>المعلومات غير الشخصية</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              ، التي لا تكشف بشكل مباشر أو غير مباشر عن هويتك أو تتعلق بشكل مباشر بفرد
              محدد، مثل المعلومات الديموغرافية أو المعلومات الإحصائية أو المجمعة. لا
              تحدد البيانات الإحصائية أو المجمعة شخصًا معينًا بشكل مباشر ، ولكن قد
              نستمد بيانات إحصائية غير شخصية أو بيانات مجمعة من المعلومات الشخصية. على
              سبيل المثال ، قد نقوم بتجميع المعلومات الشخصية لحساب النسبة المئوية
              للمستخدمين الذين يصلون إلى ميزة معينة في موقع الويب
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات الفنية، بما في ذلك معلومات تسجيل الدخول الخاصة بك ، ونوع
              المتصفح وإصداره ، وإعداد المنطقة الزمنية ، وأنواع وإصدارات المكونات
              الإضافية للمتصفح ، ونظام التشغيل والنظام الأساسي ، أو معلومات حول اتصالك
              بالإنترنت ، والمعدات التي تستخدمها للوصول إلى موقعنا الإلكتروني ،
              وتفاصيل الاستخدام
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل غير شخصية حول تفاعلاتك على الويب ، بما في ذلك محددات مواقع الويب
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;(URLs)&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              الكاملة ، ومسار النقر إلى ومن خلال ومن موقعنا (بما في ذلك التاريخ
              والوقت)، والمنتجات التي شاهدتها أو بحثت عنها ، وأوقات استجابة الصفحة،
              وأخطاء التنزيل، والطول عدد الزيارات إلى صفحات معينة، ومعلومات تفاعل
              الصفحة (مثل التمرير والنقرات وتمرير الماوس)، والطرق المستخدمة للتصفح
              بعيدًا عن الصفحة، أو أي رقم هاتف مستخدم للاتصال برقم خدمة العملاء لدينا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>كيف نجمع المعلومات عنك</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نستخدم طرقًا مختلفة لجمع معلوماتك ، بما في ذلك من خلال
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              التفاعلات المباشرة معك عندما تزودنا بها، على سبيل المثال، عن طريق ملء
              النماذج أو المراسلة معنا عبر الهاتف أو البريد الإلكتروني أو غير ذلك
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              التقنيات أو التفاعلات الآلية، أثناء تنقلك عبر موقعنا الإلكتروني. قد
              تتضمن المعلومات التي يتم جمعها تلقائيًا تفاصيل الاستخدام وعناوين
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;IP&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              والمعلومات التي تم جمعها من خلال ملفات تعريف الارتباط وإشارات الويب
              وتقنيات التتبع الأخرى
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>المعلومات التي تقدمها لنا</span>
          </strong>
          <strong>
            <span style={{ fontSize: 19 }}>:&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            المعلومات التي نجمعها منك مباشرة على أو من خلال موقعنا قد تشمل
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات التي تقدمها من خلال ملء النماذج الموجودة على موقعنا
              الإلكتروني. يتضمن ذلك المعلومات المقدمة في وقت التسجيل لاستخدام موقعنا ،
              والاشتراك في خدمتنا، ونشر المواد، وطلب المزيد من الخدمات. قد نطلب منك
              أيضًا معلومات عند دخولك في مسابقة أو عرض ترويجي برعاية، وعندما تبلغ عن
              مشكلة في موقعنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              سجلات ونسخ من مراسلاتك (بما في ذلك عناوين البريد الإلكتروني) ، إذا اتصلت
              بنا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              ردودك على الاستبيانات التي قد نطلب منك إكمالها لأغراض البحث
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              نسخ من بطاقات الهوية ، والتي قد تتضمن صورة
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>بيانات البنك / بطاقة الائتمان</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              معلومات مستخدم وسائل التواصل الاجتماعي
            </span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل المعاملات التي تقوم بإجرائها من خلال موقعنا الإلكتروني وتنفيذ
              طلباتك. قد يُطلب منك تقديم معلومات مالية قبل تقديم طلب عبر موقعنا
              الإلكتروني
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>استفسارات البحث الخاصة بك على الموقع</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; يمكنك أيضًا تقديم معلومات ليتم نشرها أو عرضها (يشار إليها
            فيما يلي باسم "منشورة") في المناطق العامة بالموقع الإلكتروني أو نقلها إلى
            مستخدمين آخرين للموقع أو أطراف ثالثة (يشار إليها مجتمعة باسم "مساهمات
            المستخدم"). يتم نشر مساهمات المستخدم الخاصة بك وإرسالها إلى الآخرين على
            مسؤوليتك الخاصة. يرجى العلم أنه لا توجد إجراءات أمنية مثالية. بالإضافة إلى
            ذلك ، لا يمكننا التحكم في تصرفات المستخدمين الآخرين للموقع الذين قد تختار
            مشاركة مساهمات المستخدم الخاصة بك معهم. لذلك ، لا يمكننا ولا نضمن أن
            الأشخاص غير المصرح لهم لن يشاهدوا مساهمات المستخدم الخاصة بك
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              المعلومات التي نجمعها من خلال ملفات تعريف الارتباط وتقنيات جمع البيانات
              التلقائية الأخرى
            </span>
          </strong>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            أثناء تنقلك عبر موقعنا الإلكتروني والتفاعل معه ، قد نستخدم ملفات تعريف
            الارتباط أو تقنيات جمع البيانات التلقائية الأخرى لجمع معلومات معينة حول
            أجهزتك وإجراءات التصفح والأنماط الخاصة بك ، بما في ذلك
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تفاصيل زياراتك إلى موقعنا الإلكتروني ، بما في ذلك بيانات حركة المرور
              وبيانات الموقع والسجلات وبيانات الاتصال الأخرى والموارد التي تصل إليها
              وتستخدمها على الموقع
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              معلومات حول الكمبيوتر والاتصال بالإنترنت ، بما في ذلك عنوان
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;IP&nbsp;</span>
            <span style={{ fontSize: 19 }}>ونظام التشغيل ونوع المتصفح</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; يجوز لنا أيضًا استخدام هذه التقنيات لجمع معلومات حول أنشطتك
            عبر الإنترنت بمرور الوقت وعبر مواقع ويب الجهات الخارجية أو الخدمات الأخرى
            عبر الإنترنت. المعلومات التي نجمعها تلقائيًا ، وقد نحتفظ بها أو نربطها
            بالمعلومات الشخصية التي نجمعها بطرق أخرى ، أو التي تقدمها لنا ، أو نتلقاها
            من أطراف ثالثة. يساعدنا ذلك على تحسين موقعنا الإلكتروني وتقديم خدمة أفضل
            وأكثر تخصيصًا ، بما في ذلك عن طريق تمكيننا من
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>تقدير حجم جمهورنا وأنماط الاستخدام</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              تخزين المعلومات حول تفضيلاتك ، مما يسمح لنا بتخصيص موقعنا وفقًا
              لاهتماماتك الفردية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>تسريع عمليات البحث</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>التعرف عليك عندما تعود إلى موقعنا</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>الكشف عن المعلومات الخاصة بك</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; قد نكشف عن معلومات مجمعة حول مستخدمينا، ومعلومات لا تحدد
            هوية أي فرد، دون قيود وقد نكشف عن المعلومات الشخصية التي نجمعها أو نوفرها
            كما هو موضح في سياسة الخصوصية هذه
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              لفروعنا والشركات التابعة لنا. وفقًا للقانون المعمول به ، لمشتري أو خلف
              آخر في حالة الاندماج أو التجريد أو إعادة الهيكلة أو إعادة التنظيم أو
              الحل أو أي بيع أو نقل آخر لبعض أو كل أصول شركة طموح للتسويق والخدمات
              الإلكترونيةذ.م.م.، سواء كانت مستمرة أو كجزء من حالة إفلاس أو تصفية أو
              إجراءات مماثلة ، حيث تكون المعلومات الشخصية التي تحتفظ بها الشركة عن
              عملائنا ومستخدمينا من بين الأصول المنقولة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              للمعلنين وشبكات الإعلانات التي تتطلب المعلومات لاختيار الإعلانات ذات
              الصلة وتقديمها لك وللآخرين. لا نكشف عن بيانات حول الأفراد الذين يمكن
              تحديد هويتهم لمعلنينا، ولكن قد نزودهم بمعلومات مجمعة عن مستخدمينا (على
              سبيل المثال، قد نعلمهم أن 400 امرأة بين 30 و 45 قد نقرن على إعلانهن في
              يوم محدد). قد نستخدم أيضًا هذه المعلومات المجمعة لمساعدة المعلنين على
              استهداف جمهور معين (على سبيل المثال ، الرجال في مكان معين). قد نستخدم
              المعلومات الشخصية التي نجمعها منك لعرض إعلانات معلنينا على الجماهير
              المستهدفة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              لأطراف ثالثة لتسويق منتجاتهم أو خدماتهم لك إذا كنت قد وافقت / لم تختر
              عدم المشاركة في عمليات الكشف هذه. نطلب تعاقديًا من هذه الأطراف الثالثة
              الحفاظ على سرية المعلومات الشخصية واستخدامها فقط للأغراض التي من أجلها
              نكشف عنها لهم. لمزيد من المعلومات، راجع خيارات حول كيفية استخدامنا
              لمعلوماتك والكشف عنها
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              للمقاولين ومقدمي الخدمات والأطراف الثالثة الأخرى التي نستخدمها لدعم
              أعمالنا، مثل موفري التحليلات ومحركات البحث التي تساعدنا على تحسين
              خدماتنا وتحسينها. نطلب تعاقديًا من هذه الأطراف الثالثة الحفاظ على سرية
              المعلومات الشخصية، واستخدامها فقط للأغراض التي من أجلها نكشف عنها لهم،
              ومعالجة المعلومات الشخصية باتباع نفس المعايير المنصوص عليها في هذه
              السياسة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              لتحقيق الغرض الذي تقدمه من أجله. على سبيل المثال، إذا أعطيتنا عنوان بريد
              إلكتروني لاستخدام ميزة "إرسال بريد إلكتروني إلى صديق" ، فسننقل محتويات
              هذا البريد الإلكتروني وعنوان بريدك الإلكتروني إلى المستلمين
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              لأي غرض آخر نقوم بالكشف عنه عند تقديم المعلومات
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>بموافقتك</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              للامتثال لأي أمر محكمة أو قانون أو إجراء قانوني، بما في ذلك الرد على أي
              طلب حكومي أو تنظيمي، وفقًا للقانون المعمول به
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              لإنفاذ حقوقنا الناشئة عن أي عقود بينك وبيننا
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              إذا كنا نعتقد أن الإفصاح ضروري أو مناسب لحماية حقوق أو ممتلكات أو سلامة
              الشركة أو عملائنا أو غيرهم. ويشمل ذلك تبادل المعلومات مع الشركات
              والمؤسسات الأخرى لمنع الاحتيال والحد من مخاطر الائتمان
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            اختيارات حول كيفية استخدامنا لمعلوماتك والكشف عنها
          </span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نسعى جاهدين لتزويدك بالخيارات المتعلقة بالمعلومات الشخصية التي تزودنا بها.
            لقد أنشأنا آليات لتزويدك بالسيطرة التالية على معلوماتك
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              &nbsp;تقنيات التتبع والإعلان. يمكنك ضبط المتصفح الخاص بك لرفض كل أو بعض
              ملفات تعريف الارتباط الخاصة بالمتصفح، أو لتنبيهك عند إرسال ملفات تعريف
              الارتباط. يمكنك اختيار السماح للتطبيق بجمع المعلومات من خلال تقنيات
              التتبع الأخرى عن طريق الاتصال بنا مباشرة. إذا قمت بتعطيل ملفات تعريف
              الارتباط أو رفضتها أو منعت استخدام تقنيات التتبع الأخرى ، فقد يتعذر
              الوصول إلى بعض أجزاء هذا التطبيق أو قد لا تعمل بشكل صحيح. لمزيد من
              المعلومات حول تقنيات التعقب، راجع تقنيات التجميع والتعقب التلقائي
              للمعلومات
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>•&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              معلومات الموقع. يمكنك اختيار السماح للتطبيق بجمع واستخدام معلومات في
              الوقت الفعلي حول موقع جهازك. إذا حظرت استخدام معلومات الموقع، فقد يتعذر
              الوصول إلى بعض أو كل أجزاء التطبيق أو لا تعمل بشكل صحيح
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "justify",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>•&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              الإعلانات المستهدفة. إذا كنت لا تريد منا استخدام المعلومات التي نجمعها
              أو التي تزودنا بها لتقديم إعلانات وفقًا لتفضيلات الجمهور المستهدف
              للمعلنين ، فيمكنك إلغاء الاشتراك عن طريق الاتصال بنا مباشرة. لكي تعمل
              آلية إلغاء الاشتراك هذه، يجب أن يكون جهازك مضبوطًا على قبول ملفات تعريف
              ارتباط المتصفح. يمكنك دائمًا ضبط تفضيلات إعلانات المستخدم الخاصة بك في
              ملف تعريف حسابك عن طريق تحديد أو إلغاء تحديد المربعات ذات الصلة أو عن
              طريق إرسال بريد إلكتروني إلينا أو رسالة نصية توضح طلبك
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>أمن البيانات</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; أمن معلوماتك الشخصية مهم جدًا بالنسبة لنا. نحن نستخدم تدابير
            مادية وإلكترونية وإدارية مصممة لتأمين معلوماتك الشخصية من الضياع العرضي
            ومن الوصول غير المصرح به والاستخدام والتعديل والإفشاء. نقوم بتخزين جميع
            المعلومات التي تزودنا بها خلف جدران الحماية على خوادمنا الآمنة. سيتم تشفير
            أي معاملات دفع ومعلومات أخرى باستخدام تقنية
          </span>
          <span style={{ fontSize: 19 }}>&nbsp;SSL</span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; &nbsp;تعتمد سلامة وأمن معلوماتك عليك أيضًا. عندما نقدم لك
            (أو اخترت) كلمة مرور للوصول إلى أجزاء معينة من تطبيقنا ، فأنت مسؤول عن
            الحفاظ على سريتها. نطلب منك عدم مشاركة كلمة المرور الخاصة بك مع أي شخص.
            نحثك على توخي الحذر بشأن إعطاء المعلومات في المناطق العامة من التطبيق مثل
            لوحات الرسائل ، والتي يمكن لأي زائر موقع ويب عرضها
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; للأسف فأن نقل المعلومات عبر الإنترنت ومنصات الهاتف المحمول
            ليس آمنًا تمامًا. على الرغم من أننا نبذل قصارى جهدنا لحماية معلوماتك
            الشخصية، لا يمكننا ضمان أمان معلوماتك الشخصية المنقولة عبر التطبيق. أي نقل
            للمعلومات الشخصية يكون على مسؤوليتك الخاصة. نحن لسنا مسؤولين عن التحايل
            على أي إعدادات خصوصية للتطبيق أو إجراءات أمنية .&nbsp;
          </span>
        </p>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>الاحتفاظ بالبيانات</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; باستثناء ما هو مسموح به أو مطلوب من قبل القانون أو اللوائح
            المعمول بها ، سنحتفظ فقط بمعلوماتك الشخصية طالما كان ذلك ضروريًا لتحقيق
            الأغراض التي جمعناها من أجلها، بما في ذلك لأغراض تلبية أي متطلبات قانونية
            أو محاسبية أو إعداد تقارير. في بعض الظروف، قد نجعل معلوماتك الشخصية مجهولة
            أو نجمعها بحيث لا يمكن ربطها بك. نحتفظ بالحق في استخدام مثل هذه البيانات
            مجهولة المصدر وغير محددة الهوية لأي غرض تجاري مشروع دون إشعار آخر لك أو
            بموافقتك. بشكل عام ، نحتفظ ببياناتك الشخصية لمدة 10 سنوات
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>أي شخص تحت سن 18</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            موقعنا الإلكتروني غير مخصص لأي شخص يقل عمره عن&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>18</span>
          <span style={{ fontSize: 19 }}>
            &nbsp;عامًا. لا يجوز لأي شخص أقل من&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>18</span>
          <span style={{ fontSize: 19 }}>
            &nbsp;عامًا تقديم أي معلومات شخصية إلى أو على الموقع. نحن لا نجمع معلومات
            شخصية عن قصد من أي شخص أقل من&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>18</span>
          <span style={{ fontSize: 19 }}>&nbsp;عامًا. إذا كان عمرك أقل من&nbsp;</span>
          <span style={{ fontSize: 19 }}>18</span>
          <span style={{ fontSize: 19 }}>
            &nbsp;عامًا ، فلا تستخدم أو تقدم أي معلومات على هذا الموقع أو على أو من
            خلال أي من ميزاته / التسجيل على موقع الويب، وقم بإجراء أي عمليات شراء من
            خلال الموقع، واستخدم أي من ميزات التعليق التفاعلي أو العام لهذا الموقع، أو
            تقديم أي معلومات عنك لن، بما في ذلك اسمك وعنوانك ورقم هاتفك وعنوان بريدك
            الإلكتروني أو أي اسم شاشة أو اسم مستخدم قد تستخدمه. إذا علمنا أننا جمعنا
            أو تلقينا معلومات شخصية من أي شخص أقل من&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>18</span>
          <span style={{ fontSize: 19 }}>
            &nbsp;عامًا دون التحقق من موافقة الوالدين، فسنحذف هذه المعلومات. إذا كنت
            تعتقد أنه قد يكون لدينا أي معلومات من أو عن أي شخص أقل من&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>18</span>
          <span style={{ fontSize: 19 }}>
            &nbsp;عامًا، فيرجى الاتصال بنا مباشرة على&nbsp;
          </span>
          <span style={{ fontSize: 19 }}>info@ezone.ly.&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>&nbsp;</span>
        </p>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>الوصول إلى معلوماتك الشخصية وتصحيحها</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; من المهم أن تكون المعلومات الشخصية التي نحتفظ بها عنك دقيقة
            وحديثة. يرجى إطلاعنا إذا تغيرت معلوماتك الشخصية. بموجب القانون ، يحق لك
            طلب الوصول إلى المعلومات الشخصية التي نحتفظ بها عنك وتصحيحها
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; يمكنك مراجعة معلوماتك الشخصية وتغييرها عن طريق تسجيل الدخول
            إلى التطبيق وزيارة صفحة ملف تعريف حسابك
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; إذا كنت ترغب في مراجعة أو التحقق من أو تصحيح أو سحب الموافقة
            على استخدام معلوماتك الشخصية، يمكنك أيضًا إرسال بريد إلكتروني إلينا على
            &nbsp;
          </span>
          <a href="mailto:Info@ezone.ly">
            <span style={{ fontSize: 19 }}>Info@ezone.ly</span>
          </a>
          <span style={{ fontSize: 19 }}>
            &nbsp;لطلب الوصول إلى أو تصحيح أو حذف أي معلومات شخصية قدمتها إلى نحن. لا
            يمكننا حذف معلوماتك الشخصية إلا بحذف حساب المستخدم الخاص بك. قد لا نلبي
            طلبًا لتغيير المعلومات إذا اعتقدنا أن التغيير ينتهك أي قانون أو شرط قانوني
            أو يتسبب في أن تكون المعلومات غير صحيحة. قد نفرض عليك رسومًا للوصول إلى
            معلوماتك الشخصية ، ومع ذلك ، سنخطرك بأي رسوم مقدمًا.
          </span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; قد نطلب منك معلومات محددة لمساعدتنا في تأكيد هويتك وحقك في
            الوصول وتزويدك بالمعلومات الشخصية التي نحتفظ بها عنك أو إجراء التغييرات
            المطلوبة. قد يسمح القانون المعمول به أو يطلب منا رفض تزويدك بإمكانية
            الوصول إلى بعض أو كل المعلومات الشخصية التي نحتفظ بها عنك، أو ربما قمنا
            بتدمير معلوماتك الشخصية أو محوها أو جعلها مجهولة الهوية وفقًا لالتزامات
            الاحتفاظ بالسجلات والممارسات. إذا لم نتمكن من تزويدك بإمكانية الوصول إلى
            معلوماتك الشخصية، فسنبلغك بأسباب ذلك، مع مراعاة أي قيود قانونية أو تنظيمية
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            سنوفر الوصول إلى معلوماتك الشخصية، مع مراعاة الاستثناءات المنصوص عليها في
            تشريعات الخصوصية المعمول بها. من أمثلة هذه الاستثناءات ما يلي
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات المحمية بامتياز المحامي - العميل
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات التي هي جزء من عملية تسوية المنازعات الرسمية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              معلومات تتعلق بفرد آخر تكشف عن معلوماته الشخصية أو معلومات تجارية سرية
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16
            }}
          >
            <span style={{ fontSize: 19 }}>
              المعلومات التي يكون توفيرها باهظة التكلفة
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            إذا كنت قلقًا بشأن ردنا أو ترغب في تصحيح المعلومات المقدمة ، يمكنك الاتصال
            بنا مباشرة&nbsp;
          </span>
          <a href="mailto:info@ezone.ly">
            <span style={{ fontSize: 19 }}>على</span>
            <span style={{ fontSize: 19 }}>&nbsp;info@ezone.ly</span>
          </a>
          <span style={{ fontSize: 19 }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            إذا قمت بحذف مساهمات المستخدم الخاصة بك من التطبيق ، فقد تظل نسخ مساهمات
            المستخدم الخاصة بك قابلة للعرض في الصفحات المخزنة مؤقتًا والأرشيف أو ربما
            تم نسخها أو تخزينها بواسطة مستخدمي التطبيق الآخرين. يخضع الوصول والاستخدام
            الصحيح للمعلومات المقدمة على التطبيق ، بما في ذلك مساهمات المستخدم ، لشروط
            الاستخدام الخاصة بنا
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>سحب موافقتك</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; في حالة موافقتك على جمع معلوماتك الشخصية واستخدامها ونقلها ،
            فقد يكون لك الحق القانوني في سحب موافقتك في ظل ظروف معينة. لسحب موافقتك ،
            إن أمكن ، اتصل&nbsp;
          </span>
          <a href="mailto:info@ezone.ly">
            <span style={{ fontSize: 19 }}>بنا</span>
            <span style={{ fontSize: 19 }}>&nbsp;info@ezone.ly</span>
          </a>
          <span style={{ fontSize: 19 }}>
            &nbsp; يرجى ملاحظة أنه إذا قمت بسحب موافقتك، فقد لا نتمكن من تزويدك بمنتج
            أو خدمة معينة. سنشرح لك التأثير في ذلك الوقت لمساعدتك في اتخاذ قرارك.
          </span>
        </p>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>حقوقك</span>
          <span style={{ fontSize: 19 }}>:</span>
        </h2>
        <ol style={{ listStyleType: "decimal" }}>
          <li>
            <span style={{ fontSize: 19 }}>الحق في الوصول</span>
            <span style={{ fontSize: 19 }}>:&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              يحق لك طلب نسخة من البيانات الشخصية التي نحتفظ بها عنك عن طريق الاتصال
              بنا على البريد الإلكتروني المقدم. يرجى تضمين معلومات طلبك التي ستمكننا
              من التحقق من هويتك. سنرد خلال 30 يومًا من الطلب. يرجى ملاحظة أن هناك
              استثناءات لهذا الحق. قد لا نتمكن من إتاحة جميع المعلومات لك إذا كان، على
              سبيل المثال، إتاحة المعلومات لك من شأنه أن يكشف عن بيانات شخصية عن شخص
              آخر، أو إذا تم منعنا قانونًا من الكشف عن هذه المعلومات أو إذا كان طلبك
              بلا أساس أو مبالغ فيه
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span style={{ fontSize: 19 }}>الحق في التصحيح</span>
            <span style={{ fontSize: 19 }}>:&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              نهدف إلى الحفاظ على دقة بياناتك الشخصية واكتمالها. نشجعك على الاتصال بنا
              باستخدام تفاصيل الاتصال الواردة أدناه لإعلامنا إذا كان أي من بياناتك
              الشخصية غير دقيق أو يتغير ، حتى نتمكن من تحديث بياناتك الشخصية باستمرار
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span style={{ fontSize: 19 }}>
              الحق في الحذف:&nbsp;يحق لك طلب حذف بياناتك الشخصية حيث، على سبيل المثال،
              لم تعد البيانات الشخصية ضرورية للأغراض التي تم جمعها من أجلها ، حيث تسحب
              موافقتك على المعالجة، حيث لا يوجد تجاوز. مصلحة مشروعة لنا لمواصلة معالجة
              بياناتك الشخصية، أو تمت معالجة بياناتك الشخصية بشكل غير قانوني. إذا كنت
              ترغب في طلب مسح بياناتك الشخصية ، فيرجى الاتصال
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
            <span>
              <a href="mailto:info@ezone.ly">
                <span style={{ fontSize: 19 }}>بنا</span>
                <span style={{ fontSize: "14.0pt" }}>&nbsp;info@ezone.ly</span>
              </a>
            </span>
            <span style={{ fontSize: 19 }}>&nbsp; .&nbsp;</span>
          </li>
          <li>
            <span style={{ fontSize: 19 }}>
              الحق في الاعتراض:&nbsp;في ظروف معينة ، يحق لك الاعتراض على معالجة
              بياناتك الشخصية حيث، على سبيل المثال، تتم معالجة بياناتك الشخصية على
              أساس المصالح المشروعة ولا توجد مصلحة مشروعة غالبة بالنسبة لنا للاستمرار
              معالجة بياناتك الشخصية ، أو في حالة معالجة بياناتك لأغراض التسويق
              المباشر. إذا كنت ترغب في الاعتراض على الضغط على بياناتك الشخصية ، فيرجى
              الاتصال بنا
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
            <span>
              <a href="mailto:info@ezone.ly">
                <span>على</span>
                <span style={{ fontSize: 19 }}>&nbsp;info@ezone.ly</span>
              </a>
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;.&nbsp;</span>
          </li>
          <li>
            <span style={{ fontSize: 19 }}>الحق في تقييد المعالجة</span>
            <span style={{ fontSize: 19 }}>:&nbsp;</span>
            <span style={{ fontSize: 19 }}>
              في ظروف معينة ، يحق لك طلب تقييد المعالجة الإضافية لبياناتك الشخصية.
              ينشأ هذا الحق ، على سبيل المثال ، عندما تكون قد طعنت في دقة البيانات
              الشخصية التي نحتفظ بها عنك ونحن نتحقق من المعلومات ، واعترضت على
              المعالجة بناءً على المصالح المشروعة وننظر فيما إذا كانت هناك أي اهتمامات
              مشروعة سائدة ، المعالجة غير قانونية وأنت تختار أن المعالجة مقيدة بدلاً
              من حذفها. يرجى الاتصال بنا باستخدام تفاصيل الاتصال الواردة أدناه
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li>
            <span style={{ fontSize: 19 }}>
              الحق في إمكانية نقل البيانات:&nbsp;في ظروف معينة ، يحق لك طلب توفير بعض
              بياناتك الشخصية لك ، أو إلى وحدة تحكم أخرى في البيانات ، بتنسيق شائع
              الاستخدام ويمكن قراءته آليًا. ينشأ هذا الحق عندما تقدم بياناتك الشخصية
              إلينا ، وتعتمد المعالجة على الموافقة أو أداء العقد ، وتتم المعالجة
              بوسائل آلية. إذا كنت ترغب في طلب نقل بياناتك الشخصية إليك ، فيرجى
              الاتصال بنا
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
            <span>
              <a href="mailto:info@ezone.ly">
                <span>على</span>
                <span style={{ fontSize: 19 }}>&nbsp;info@ezone.ly</span>
              </a>
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;.&nbsp;</span>
          </li>
        </ol>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;يرجى ملاحظة أن هناك استثناءات لهذه الحقوق، على سبيل المثال،
            إذا كنا ملزمين قانونيًا بالاستمرار في معالجة بياناتك الشخصية. إذا لم نتمكن
            من الامتثال لطلبك بسبب استثناء ، فسنشرح لك ذلك في ردنا
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>التغييرات في سياسة الخصوصية الخاصة بنا</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp; قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من حين لآخر. تتمثل
            سياستنا في نشر أي تغييرات نجريها على سياسة الخصوصية الخاصة بنا على هذه
            الصفحة مع إشعار بأننا قمنا بتحديث سياسة الخصوصية. إذا قمنا بإجراء تغييرات
            جوهرية على كيفية تعاملنا مع المعلومات الشخصية لمستخدمينا ، فسنقوم بنشر
            سياسة الخصوصية الجديدة على هذه الصفحة مع إشعار بأنه تم تحديث سياسة
            الخصوصية وإخطارك بالبريد الإلكتروني المحدد في حسابك
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            marginBottom: ".0001pt",
            textAlign: "justify"
          }}
        >
          <span style={{ fontSize: 19 }}>
            &nbsp; &nbsp;نقوم بتضمين تاريخ آخر مراجعة لسياسة الخصوصية في أعلى الصفحة.
            أنت مسؤول عن ضمان حصولنا على عنوان بريد إلكتروني محدث ونشط وقابل للتسليم
            لك، وزيارة سياسة الخصوصية هذه بشكل دوري للتحقق من أي تغييرات
          </span>
          <span style={{ fontSize: 19 }}>.</span>
        </p>
        <h2
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>معلومات الاتصال وتحدي الامتثال</span>
        </h2>
        <p
          style={{
            marginRight: "0in",
            marginLeft: "0in",
            fontSize: 16,
            margin: "0in",
            marginBottom: ".0001pt",
            textAlign: "right"
          }}
        >
          <span style={{ fontSize: 19 }}>
            نرحب بأسئلتك وتعليقاتك وطلباتك فيما يتعلق بسياسة الخصوصية هذه وممارسات
            الخصوصية لدينا. يرجى الاتصال بنا على
          </span>
          <span style={{ fontSize: 19 }}>:</span>
        </p>
        <ul style={{ marginBottom: "0in", listStyle: "disc" }}>
          {/* <li style='margin:0in;margin-bottom:.0001pt;text-align:right;font-size:16px;margin-left:11.25pt;'>
            <a href="mailto:info@ezone.ly"><span style='font-size:19px;'>info@ezone.ly</span></a><span
                style='font-size:19px;'>&nbsp;</span>
        </li> */}
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              شركة طموح للتسويق والخدمات الإلكترونيةذ.م.م
            </span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              مبني ريم البوادي – الدور الرابع- , منطقة السياحية –ابونواس - طرابلس –
              ليبيا
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>موبايل : 8829999 91 218+</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>موبايل : 8829999 92 218+</span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
          </li>
          <li
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              textAlign: "right",
              fontSize: 16,
              marginLeft: "11.25pt"
            }}
          >
            <span style={{ fontSize: 19 }}>
              لدينا إجراءات معمول بها لتلقي الشكاوى أو الاستفسارات والرد عليها حول
              تعاملنا مع المعلومات الشخصية ، وامتثالنا لهذه السياسة، وقوانين الخصوصية
              المعمول بها. لمناقشة امتثالنا لهذه السياسة ، يرجى الاتصال بنا على
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
            <span>
              <a href="mailto:info@ezone.ly">
                <span style={{ fontSize: 19 }}>info@ezone.ly</span>
              </a>
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
            <span style={{ fontSize: 19 }}>ب</span>
            <span style={{ fontSize: 19 }}>
              استخدام معلومات الاتصال المذكورة أعلاه
            </span>
            <span style={{ fontSize: 19 }}>&nbsp;</span>
            <span style={{ fontSize: 19 }}>.</span>
          </li>
        </ul>
      </div>
      





    );
}